import axios from 'axios';
import store from '../store';
import {baseURL, appVersion} from '../utils/Config';
import { headers, checkAuth } from '../utils/Config';
import Cookies from 'js-cookie';


export function retrieveUser() {
  axios.defaults.baseURL = baseURL();
  axios.defaults.headers.common['x-app-version'] = appVersion();
  const authToken = store.getState().getIn(['app', 'token']);
  axios.defaults.headers.common['authorization'] = 'Bearer ' + authToken;
  var buster = Math.round(new Date().getTime() / 1000);
  axios.get('/api/v1/admin/user/?t='+buster)
  .then(res => {
    const userData = res.data.user;
    console.log("userData");
    console.log(userData);
    store.dispatch({type: "SET_USER_DATA", data: userData});
    store.dispatch({type: "SHOW_APP"});
  })
  .catch(function(error) {
    console.log(error);
    checkAuth(error);
  });
}

export function fetchUsers() {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'GET',
    url: '/api/v1/admin/users/?t=' + buster,
    responseType: 'json',
    data: {}
  }).then((response) => {
    let users = JSON.parse(JSON.stringify(response.data.users));
    store.dispatch({ type: "SET_USERS", users: users });
    return { status: response.status, data: response.data };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function createUser() {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    url: '/api/v1/admin/users/?t=' + buster,
    responseType: 'json',
    data: {}
  }).then((response) => {
    return { status: response.status, data: response.data };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function sendSmsCode(phone) {
  let instance = axios.create(headers());
  axios.defaults.withCredentials = true;
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    url: '/api/v1/admin/send-sms-code/?t=' + buster,
    responseType: 'json',
    data: {phone: phone}
  }).then((response) => {
    return { status: response.status, data: response.data };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function verifyCode(phone, code) {
  let instance = axios.create(headers());
  axios.defaults.withCredentials = true;
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    url: '/api/v1/admin/verify-sms/?t=' + buster,
    responseType: 'json',
    data: {phone: phone, code: code}
  }).then((response) => {
    if (response.status === 200) {
      let id = response.data.id;
      window.location = `/users/${id}`;
    }
    return { status: response.status, data: response.data };
  }).catch(function (error) {
    console.log(error);
  });
}

export function profile() {
  let instance = axios.create(headers());
  axios.defaults.withCredentials = true;
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'GET',
    url: '/api/v1/admin/user/profile/?t=' + buster,
    responseType: 'json',
    data: { }
  }).then((response) => {
    return { status: response.status, data: response.data };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function fetchUser(id) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'GET',
    url: `/api/v1/admin/users/${id}?t=${buster}`,
    responseType: 'json',
    data: {}
  }).then((response) => {
    let user = JSON.parse(JSON.stringify(response.data.user));
    store.dispatch({ type: "SET_USER", user: user });
    return { status: response.status, data: response.data };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function setCurrentUser(id) {
  store.dispatch({ type: "SET_CURRENT_USER", id: id })
}

export function updateUser(id, data) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'PATCH',
    url: `/api/v1/admin/users/${id}?t=${buster}`,
    responseType: 'json',
    data: {user: data}
  }).then((response) => {
    let user = JSON.parse(JSON.stringify(response.data.user));
    store.dispatch({ type: "SET_USER", user: user });
    return { status: response.status, data: response.data };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function updateUserAchievements(id, data) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'PATCH',
    url: `/api/v1/admin/users/${id}/achievements?t=${buster}`,
    responseType: 'json',
    data: {user: data}
  }).then((response) => {
    let user = JSON.parse(JSON.stringify(response.data.user));
    store.dispatch({ type: "SET_USER", user: user });
    return { status: response.status, data: response.data };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function removeFromDrop(userId, dropId) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    url: `/api/v1/admin/users/${userId}/remove-from-drop?t=${buster}`,
    responseType: 'json',
    data: { drop_uuid: dropId }
  }).then((response) => {
    if (response.status === 200) {
      window.location = `/users/${userId}`;
    }
    return { status: response.status, data: response.data };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function tryLogin(email, pass) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    url: `/api/v1/admin/login?t=${buster}`,
    responseType: 'json',
    data: { email: email, password: pass }
  }).then((response) => {
    if (response.status === 200) {
      store.dispatch({type: "LOGIN"});
      Cookies.set('authToken', response.data.auth_token);
      window.location = `/control-tower`;
    }
    return { status: response.status, data: response.data };
  }).catch(function (error) {
    console.log(error);
  });
}


export function home() {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'GET',
    url: `/api/v1/`,
    responseType: 'json',
    data: {}
  }).then((response) => {
    console.log("HEYYYYYYY!!!!!");
    console.log(response);
    console.log(response.data);
    console.log("parse it now");
    console.log(JSON.parse(JSON.stringify(response.data)));
    return { status: response.status, data: response.data };
  }).catch(function (error) {
    console.log(error);
  });
}