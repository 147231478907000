import React, { Component } from 'react';
import store from '../store';
import {fromJS} from 'immutable';
import {connect} from 'react-redux';
import { Link } from "react-router-dom";
import { loadingProducts, sync, setCurrentProduct } from '../actions/productActions';
import { baseURL } from '../utils/Config';

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentWillMount() {
    setCurrentProduct(null);
  }
  syncProducts() {
    loadingProducts();
    sync();
  }
  render() {
    const products = this.props.products;
    const loaded = this.props.productsLoaded;
    return (
      <div name="Products" id="products">
        <div id="hero-logo-container">
          <Link to="/control-tower">
            <img id="hero-logo" alt="Hero Logo" src="/h-mark.svg" />
          </Link>
        </div>
        { loaded ? (
          <div id="sync-products-btn" onClick={this.syncProducts.bind(this)}><div>Sync Products</div></div>
        ) : (
          <span></span>
        )}
        { loaded ? (
          <div name="hero-products" id="products-container">
            { products.map((product, i) => {
              let title = product.title;
              let variants = product.variants;
              let picUrl = null;
              let base = baseURL();
              let product_images = product.images;
              let picAvailable = false;
              console.log("product_images");
              console.log(product_images);
              if (product_images.length) {
                picUrl = `${product_images[0].url}`;
              } else {
                if (variants.length) {
                  let image = product.variants[0].shopify_image_url;
                  if (image) {
                    picUrl = product.variants[0].shopify_image_url;
                    picAvailable = true;
                  } else {
                    picUrl = "/h-mark.svg";
                  }
                } else {
                  picUrl = "/h-mark.svg";
                }
              }
              let uuid = product.uuid;
              let visible = product.visible;
              return (
                <Link to={"/products/" + uuid} key={i}>
                  <div className="product">
                    <div className="hero-product-container">
                      <div name="hero-product-image" className="hero-product-image-container">
                        { visible ? (
                          <img alt="visible" className="visible" src="/check.png" />
                        ) : (
                          <span/>
                        )}
                        { picAvailable ? (
                          <img alt="hero product" src={picUrl} />
                        ) : (
                          <img className="placeholder-pic" alt="hero product" src={picUrl} />
                        )}
                        <div className="product-details">
                          <div className="product-title">{title}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        ): (
          <div className = "loader"></div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const products = fromJS(store.getState().getIn(['shop', 'products'])).toJS();
  const productsLoaded = fromJS(store.getState().getIn(['shop', 'productsLoaded']));
  return { products: products, productsLoaded: productsLoaded }
}

export default connect(mapStateToProps)(Products);
