import React, { Component } from 'react';
import store from '../store';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { tryLogin } from '../actions/userActions';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSave: false,
      email: null,
      pass: null
    };
  }
  componentDidUpdate() {
  }
  componentDidMount() {
  }
  prepareEmail(event) {
    const { target: { value } } = event
    this.setState({ email: value });
    if (this.state.pass && this.state.email) {
      this.setState({ canSave: true });
    }
  }
  preparePassword(event) {
    const { target: { value } } = event
    this.setState({ pass: value });
    if (this.state.pass && this.state.email) {
      this.setState({canSave: true });
    }
  }
  onKeyPress(e) {
    if (e.key === 'Enter') {
      if (this.state.pass && this.state.email) {
        this.tryUserLogin();
      }
    }
  }
  tryUserLogin() {
    tryLogin(this.state.email, this.state.pass);
  }
  render() {
    return (
      <div name="Login" id="user">
        <div id="hero-logo-container">
          <Link to="/">
            <img id="hero-logo" alt="Hero Logo" src="/h-mark.svg" />
          </Link>
        </div>
        <div id="login-input-container">
          <div className="label-container"><label>Email</label></div>
          <input name="email" id="user-email-input" placeholder={""} value={this.state.phone} autoFocus onChange={this.prepareEmail.bind(this)} onKeyPress={this.onKeyPress.bind(this)}></input>
          <div className="label-container"><label>Password</label></div>
          <input name="password" id="user-pass-input" type="password" placeholder={""} defaultValue={null} onKeyPress={this.onKeyPress.bind(this)} onChange={this.preparePassword.bind(this)}></input>
          { this.state.canSave ? (
            <div className="submit-btn" onClick={this.tryUserLogin.bind(this)}>Submit</div>
          ) : (
            <span></span>
          )}
        </div >
        <div className="spacer" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {}
}

export default connect(mapStateToProps)(Login);
