import axios from 'axios';
import store from '../store';
import { headers, formHeaders, checkAuth } from '../utils/Config';

export function setCurrentDrop(id) {
  store.dispatch({ type: "SET_CURRENT_DROP", id: id })
}

export function fetchDrops() {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'GET',
    url: '/api/v1/admin/drops/?t=' + buster,
    responseType: 'json',
    data: {}
  }).then((response) => {
    console.log("RESPONSE DROPS");
    console.log(response);
    let drops = JSON.parse(JSON.stringify(response.data.drops));
    store.dispatch({ type: "SET_DROPS", drops: drops });
    return { status: response.status, data: response.data };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function createDrop(data)  {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: { drop: data },
    url: `/api/v1/admin/drops/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    if (response.status === 200) {
      let id = response.data.id;
      window.location = `/drops/${id}`;
    }
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function update(dropId, data) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'PATCH',
    data: { drop: data },
    url: `/api/v1/admin/drops/${dropId}/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function updateStartDate(id, date) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: { start_date: date, id: id },
    url: `/api/v1/admin/start-date/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function updateEndDate(id, date) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: { end_date: date, id: id },
    url: `/api/v1/admin/end-date/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function updateDeliveryDate(id, date) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: { delivery_date: date, id: id },
    url: `/api/v1/admin/delivery-date/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}


export function addBadge(id, image) {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  let formData = new FormData();
  formData.append("image", image);
  formData.append("type", "badge");
  instance.request({
    method: 'POST',
    data: formData,
    url: `/api/v1/admin/drops/${id}/image/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function addImage(id, image) {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  let formData = new FormData();
  formData.append("image", image);
  formData.append("type", "main");
  instance.request({
    method: 'POST',
    data: formData,
    url: `/api/v1/admin/drops/${id}/image/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function removeBadge(dropId) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    data: { type: "badge"},
    url: `/api/v1/admin/drops/${dropId}/image/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function removeImage(dropId) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    data: { type: "main" },
    url: `/api/v1/admin/drops/${dropId}/image/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function addLevel(id) {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: {},
    url: `/api/v1/admin/drops/${id}/add-level/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function deleteLevel(id) {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    data: {},
    url: `/api/v1/admin/drop-levels/${id}/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}


export function addAchievement(dropId, levelId, data) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: {level_id: levelId, data: data},
    url: `/api/v1/admin/drops/${dropId}/add-achievement/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    if (response.status === 200) {
      let achievementId = response.data.achievement_id;
      fetchDrops();
      store.dispatch({type: "SET_CURRENT_ACHIEVEMENT", id: achievementId});
    }
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function deleteAchievement(id) {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    data: {},
    url: `/api/v1/admin/achievements/${id}/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}


export function saveAchievement(dropId, achievementId, data) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'PATCH',
    data: { achievement: data },
    url: `/api/v1/admin/achievements/${achievementId}?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function addAchievementImage(id, image) {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  let formData = new FormData();
  formData.append("image", image);
  instance.request({
    method: 'POST',
    data: formData,
    url: `/api/v1/admin/achievements/${id}/image/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function addRewardImage(id, image) {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  let formData = new FormData();
  formData.append("image", image);
  instance.request({
    method: 'POST',
    data: formData,
    url: `/api/v1/admin/drops/${id}/reward-image/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function addInfoImage(id, image) {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  let formData = new FormData();
  formData.append("image", image);
  instance.request({
    method: 'POST',
    data: formData,
    url: `/api/v1/admin/achievements/${id}/info-image/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function removeAchievementImage(achievementId) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    data: { type: "image" },
    url: `/api/v1/admin/achievements/${achievementId}/image/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function removeInfoImage(achievementId) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    data: { type: "info" },
    url: `/api/v1/admin/achievements/${achievementId}/image/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function removeRewardImage(dropId) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    data: { type: "reward" },
    url: `/api/v1/admin/drops/${dropId}/image/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function updateLevel(id, data) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'PATCH',
    data: { drop_level: data },
    url: `/api/v1/admin/drop-levels/${id}/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function addLevelRewardImage(id, image) {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  let formData = new FormData();
  formData.append("image", image);
  instance.request({
    method: 'POST',
    data: formData,
    url: `/api/v1/admin/drop-levels/${id}/reward-image/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function removeLevelRewardImage(id) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    data: { type: "reward" },
    url: `/api/v1/admin/drop-levels/${id}/image/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function addTag(id, tag) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: { tag: tag },
    url: `/api/v1/admin/achievements/${id}/add-tag?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function removeTag(achievementId, tag) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    data: { tag: tag },
    url: `/api/v1/admin/achievements/${achievementId}/remove-tag?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function addFaq(id) {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: {},
    url: `/api/v1/admin/drops/${id}/add-faq/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function updateFaqs(id, data) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: {faqs: data},
    url: `/api/v1/admin/drops/${id}/update-faqs/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function savePost(id, url, platform, image) {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  let formData = new FormData();
  formData.append("image", image);
  formData.append("platform", platform);
  formData.append("url", url);
  instance.request({
    method: 'POST',
    data: formData,
    url: `/api/v1/admin/drops/${id}/save-social-post/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function selectWinners(id, number) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: { drop_id: id, number: number },
    url: `/api/v1/admin/select-potential-winners?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function reselectWinners(id) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: { drop_id: id },
    url: `/api/v1/admin/reselect-potential-winners?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function swapPotentialWinner(id) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: { potential_sweepstakes_winner_id: id },
    url: `/api/v1/admin/swap-potential-winner?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}


export function deleteUserEntry(id) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    data: { user_entry_id: id },
    url: `/api/v1/admin/remove-user-entry?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function deletePotentialWinner(id) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    data: { potential_sweepstakes_winner_id: id },
    url: `/api/v1/admin/remove-potential-sweepstakes-winner?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function confirmWinners(id) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: { drop_id: id },
    url: `/api/v1/admin/confirm-winners?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function deleteFaq(dropId, id) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    data: { faq_id: id },
    url: `/api/v1/admin/drops/${dropId}/remove-faq?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function archiveEntries(id) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: { drop_id: id },
    url: `/api/v1/admin/archive-entries?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchDrops();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}
