import React, { Component } from 'react';
import store from '../store';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { saveLocations, syncLocations } from '../actions/shopActions';
import { saveEmoji } from '../utils/Config';

class Locations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      canSaveLocations: false
    };
  }
  prepareLevelInput(event) {
    const { target: { name, value } } = event;
    this.setState({
      levelFieldsToUpdate: Object.assign(
        {},
        this.state.levelFieldsToUpdate,
        { [name]: value }
      ),
      canSaveLevel: true
    });
  }
  prepareFile(event) {
    const file = event.target.files[0];
    this.setState({
      file: file,
      canSaveLocations: true
    });
  }
  saveLocations() {
    let file = this.state.file;
    saveLocations(file);
    this.setState({ canSaveLocations: false });
  }
  sync() {
    syncLocations();
  }
  render() {
    let loaded = this.props.loaded;
    let locations = this.props.locations;
    return (
      <div name="Locations" id="locations">
        <div id="hero-logo-container">
          <Link to="/control-tower">
            <img id="hero-logo" alt="Hero Logo" src="/h-mark.svg" />
          </Link>
        </div>
        <div id="settings-content">
          {(loaded) ? (
            <div id="settings-container">
              <section className="settings-section">
                <div className="section-header">
                  <label id="settings-label">Locations</label>
                  {this.state.canSaveLocations ? (
                    <div className="save-btn" id="save" onClick={this.saveLocations.bind(this)}>
                      <div>{saveEmoji()}</div>
                    </div>
                  ) : (
                    <span></span>
                  )}
                  {(!this.state.canSaveLocations) ? (
                    <div id="locations-uploader-container">
                      <input type="file" id="locations-upload" onChange={this.prepareFile.bind(this)} />
                      <label id="add-locations-btn" htmlFor="locations-upload">Upload Locations</label>
                    </div>
                  ) : (
                    <span></span>
                  )}
                  <div id="locations-uploader-container">
                    <label id="sync-locations-btn" onClick={this.sync.bind(this)}>Sync Locations</label>
                  </div>
                </div>
                <table id="locations-table">
                  <tr>
                    <th>Name</th>
                    <th>Address</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Zipcode</th>
                    <th>Store Number</th>
                  </tr>

                  {locations.map((location, i) => {
                    return (
                      <tr className="location-details">
                        <td>{location.business_name}</td>
                        <td>{location.address_line_1}</td>
                        <td>{location.city}</td>
                        <td>{location.state}</td>
                        <td>{location.zip}</td>
                        <td>{location.store_number}</td>
                      </tr>
                    );
                  })}
                </table>
              </section>
            </div>
          ) : (
            <div className="loader" />
          )}
        </div>
        <div className="spacer" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const shopLoaded = fromJS(store.getState().getIn(['shop', 'shopLoaded']));
  const locations = fromJS(store.getState().getIn(['shop', 'locations'])).toJS();
  return { locations: locations, loaded: shopLoaded }
}

export default connect(mapStateToProps)(Locations);
