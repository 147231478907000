import React, { Component } from 'react';
import store from '../store';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { createPressRelease, addReleaseImage, saveRelease, deleteRelease, updateMediaKit } from '../actions/shopActions';
import { saveEmoji } from '../utils/Config';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsToUpdate: {},
      releaseFieldsToUpdate: {},
      canSave: false,
      canSavePressRelease: false,
      canAddPressRelease: true,
      canSaveMediaKit: true,
      showPressReleases: true,
      showEditPressRelease: false,
      showMediaKit: false
    };
  }
  prepareMediaKitInput(event) {
    const { target: { name, value } } = event
    this.setState({
      fieldsToUpdate: Object.assign(
        {},
        this.state.fieldsToUpdate,
        { [name]: value }
      ),
      canSave: true
    });
  }
  prepareReleaseInput(event) {
    const { target: { name, value } } = event;
    const faqId = event.target.dataset.faq_id;
    console.log("preparing FAQ data for save");
    console.log("name");
    console.log(name);
    console.log("val");
    console.log(value);
    console.log("faqId");
    console.log(faqId);
    this.setState({
      releaseFieldsToUpdate: Object.assign(
        {},
        this.state.releaseFieldsToUpdate,
        { [name]: value }
      ),
      canSavePressRelease: true,
      canAddPressRelease: false
    });
  }
  prepareReleaseImage(event) {
    const files = event.target.files;
    const id = event.target.dataset.id;
    addReleaseImage(id, files[0]);
  }
  deleteReleaseImage(event) {

  }
  savePressRelease() {
    let data = this.state.releaseFieldsToUpdate;
    saveRelease(data);
    this.setState({ canSavePressRelease: false, canAddPressRelease: true });
  }
  saveMediaKit() {
    updateMediaKit(this.state.fieldsToUpdate);
    this.setState({ canSaveMediaKit: false });
  }
  showEditPressRelease() {
    this.setState({showEditPressRelease: true});
  }
  removeRelease(event) {
    const id = event.target.dataset.id;
    deleteRelease(id);
  }
  addPressRelease() {
    createPressRelease();
  }
  openNavItem(section) {
    if (section === "releases") {
      this.setState({ showPressReleases: true, showMediaKit: false });
    } else if (section === "kit") {
      this.setState({ showPressReleases: false, showMediaKit: true });
    }
  }
  render() {
    let loaded = this.props.loaded;
    let pressReleases = this.props.pressReleases;
    let dropStyle;
    const mediaKit = this.props.mediaKit;
    console.log("mediaKit");
    console.log(mediaKit);
    return (
      <div name="Press" id="press">
        <div id="hero-logo-container">
          <Link to="/control-tower">
            <img id="hero-logo" alt="Hero Logo" src="/h-mark.svg" />
          </Link>
        </div>
        {(loaded) ? (
          <div id="page-nav">
            {this.state.showPressReleases ? (
              <div onClick={this.openNavItem.bind(this, "releases")} className="active-nav-btn">Press Releases</div>
            ) : (
              <div onClick={this.openNavItem.bind(this, "releases")} className="nav-btn">Press Releases</div>
            )}
            {this.state.showMediaKit ? (
              <div onClick={this.openNavItem.bind(this, "kit")} className="active-nav-btn">Media Kit</div>
            ) : (
              <div onClick={this.openNavItem.bind(this, "kit")} className="nav-btn">Media Kit</div>
            )}
          </div>
        ) : (
          <span></span>
        )}
        {this.state.showPressReleases ? (
          <div id="settings-content">
            {(loaded) ? (
              <div id="settings-container">
                <section className="settings-section">
                  <div className="section-header">
                    <label id="settings-label">Press Releases</label>
                    {this.state.canSavePressRelease ? (
                      <div className="save-btn" id="save" onClick={this.savePressRelease.bind(this)}>
                        <div>{saveEmoji()}</div>
                      </div>
                    ) : (
                      <span></span>
                    )}
                    {this.state.canAddPressRelease ? (
                      <div id="add-press-release-btn" onClick={this.addPressRelease.bind(this)}>Add Press Release</div>
                    ) : (
                      <span></span>
                    )}
                  </div>
                  {pressReleases.map((release, i) => {
                    console.log("release");
                    console.log(release);
                    const fileFieldId = `press-release-image-${release.uuid}`;
                    const releaseImageStyle = {
                      backgroundImage: `url(${release.image_url})`
                    };
                    return (
                      <div className="release-details">
                        <div className="release-options">
                          <form>
                            <div className="input-container">
                              <div><label>Title</label></div>
                              <input name={`title_${release.uuid}`} id="title-input" data-id={release.uuid} defaultValue={release.title} onChange={this.prepareReleaseInput.bind(this)}></input>
                            </div>
                            <div className="input-container">
                              <div><label>Short Intro</label></div>
                              <textarea name={`short-intro_${release.uuid}`} id="short-intro-input" data-id={release.uuid} defaultValue={release.short_intro} onChange={this.prepareReleaseInput.bind(this)}></textarea>
                            </div>
                            <div className="input-container">
                              <div><label>Body</label></div>
                              <textarea name={`body_${release.uuid}`} id="body-input" data-id={release.uuid} defaultValue={release.body} onChange={this.prepareReleaseInput.bind(this)}></textarea>
                            </div>
                            <div className="input-container">
                              <div><label>Slug</label></div>
                              <input name={`slug_${release.uuid}`} id="slug-input" data-id={release.uuid} defaultValue={release.slug} onChange={this.prepareReleaseInput.bind(this)}></input>
                            </div>
                            <div className="release-image">
                              <div className="full-input">
                                <div className="file-container">
                                  <div className="sub-section-title">
                                    <label>Image</label>
                                    <input type="file" id={fileFieldId} className="release-file-input" data-id={release.uuid} onChange={this.prepareReleaseImage} />
                                    {!release.image_url ? (
                                      <label className="file-upload-btn" htmlFor={fileFieldId}>Add +</label>
                                    ) : (
                                      <span></span>
                                    )}
                                  </div>
                                </div>
                                {release.image_url ? (
                                  <div id="drop-images">
                                    <div className="image-container">
                                      <div style={releaseImageStyle} className="drop-image"></div>
                                      <div data-release_id={release.uuid} className="delete-btn" onClick={this.deleteReleaseImage.bind(this)}>Delete</div>
                                    </div>
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                              </div>
                            </div>
                            <img alt="trash release" data-id={release.uuid} className="trash-release" src="/bin.png" onClick={this.removeRelease.bind(this)} />
                          </form>
                        </div>
                      </div>
                    );
                  })}
                </section>
              </div>
            ) : (
              <div className="loader" />
            )}
          </div>
        ) : (
          <div id="media-content">
            <section id="settings-container">
              <div className="faqs-header">
                <div className="stick-left">
                  <label>Media Kit</label>
                </div>
                {this.state.canSaveMediaKit ? (
                  <div className="save-btn" id="save" onClick={this.saveMediaKit.bind(this)}>
                    <div>{saveEmoji()}</div>
                  </div>
                ) : (
                  <span></span>
                )}
              </div>
              <div className="release-details">
                <div className="release-options">
                  <form>
                    <div className="input-container">
                      <div><label>URL</label></div>
                      <input name={`media_kit`} id="media-kit-input" defaultValue={mediaKit} onChange={this.prepareMediaKitInput.bind(this)}></input>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        )}
        <div className="spacer" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const shopLoaded = fromJS(store.getState().getIn(['shop', 'shopLoaded']));
  const mediaKit = fromJS(store.getState().getIn(['shop', 'mediaKit']));
  const pressReleases = fromJS(store.getState().getIn(['shop', 'pressReleases'])).toJS();
  return { pressReleases: pressReleases, loaded: shopLoaded, mediaKit: mediaKit }
}

export default connect(mapStateToProps)(Settings);
