import React, { Component } from 'react';
import store from '../store';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { saveElection, createChoice, saveChoices } from '../actions/shopActions';
import { saveEmoji } from '../utils/Config';

class Voting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      electionFieldsToUpdate: {},
      choiceFieldsToUpdate: {},
      canSaveChoice: false,
      canSaveElection: false,
      canAddChoice: true,
      canAddElection: false,
    };
  }
  prepareElectionCheckbox(event) {
    const { target: { name, checked } } = event
    this.setState({
      electionFieldsToUpdate: Object.assign(
        {},
        this.state.electionFieldsToUpdate,
        { [name]: checked }
      ),
      canSaveElection: true
    });
  }
  prepareElectionInput(event) {
    const { target: { name, value } } = event
    this.setState({
      electionFieldsToUpdate: Object.assign(
        {},
        this.state.electionFieldsToUpdate,
        { [name]: value }
      ),
      canSaveElection: true
    });
  }
  prepareChoiceInput(event) {
    const { target: { name, value } } = event
    this.setState({
      choiceFieldsToUpdate: Object.assign(
        {},
        this.state.choiceFieldsToUpdate,
        { [name]: value }
      ),
      canSaveChoice: true
    });
  }
  addChoice(event) {
    createChoice();
  }
  saveElection(event) {
    saveElection(this.state.electionFieldsToUpdate);
    this.setState({ canSaveElection: false });
  }
  saveChoice(event) {
    saveChoices(this.state.choiceFieldsToUpdate);
    this.setState({ canSaveChoice: false });
  }
  render() {
    let loaded = this.props.loaded;
    let elections = this.props.elections;
    let choices;
    if (elections.length) {
      choices = elections[0].choices;
    }
    console.log("elections");
    console.log(elections);
    const mediaKit = this.props.mediaKit;
    return (
      <div name="Voting" id="voting">
        <div id="hero-logo-container">
          <Link to="/control-tower">
            <img id="hero-logo" alt="Hero Logo" src="/h-mark.svg" />
          </Link>
        </div>
          <div id="settings-content">
            {(loaded) ? (
              <div id="settings-container">
                <section className="settings-section">
                  <div className="section-header">
                    <label id="settings-label">Election</label>
                    {this.state.canSaveElection ? (
                      <div className="save-btn" id="save" onClick={this.saveElection.bind(this)}>
                        <div>{saveEmoji()}</div>
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </div>
                  {elections.map((election, i) => {
                    return (
                      <div className="election-details">
                        <form>
                          <div className="input-container">
                            <div><label>Title</label></div>
                            <input name={`title_${election.uuid}`} id="title-input" data-id={election.uuid} defaultValue={election.title} onChange={this.prepareElectionInput.bind(this)}></input>
                          </div>
                          <div className="input-container">
                            <div><label>Description</label></div>
                            <textarea name={`description_${election.uuid}`} id="description-input" data-id={election.uuid} defaultValue={election.description} onChange={this.prepareElectionInput.bind(this)}></textarea>
                          </div>
                          <div className="input-container">
                            <div><label>Sub Description</label></div>
                            <textarea name={`sub-description_${election.uuid}`} id="sub-description-input" data-id={election.uuid} defaultValue={election.sub_description} onChange={this.prepareElectionInput.bind(this)}></textarea>
                          </div>
                          <div className="input-container">
                            <div><label>Main Video</label></div>
                            <input name={`main-video_${election.uuid}`} id="main-video-input" data-id={election.uuid} defaultValue={election.main_video} onChange={this.prepareElectionInput.bind(this)}></input>
                          </div>
                          <div className="input-container">
                            <div><label>Second Video</label></div>
                            <input name={`second-video_${election.uuid}`} id="second-video-input" data-id={election.uuid} defaultValue={election.second_video} onChange={this.prepareElectionInput.bind(this)}></input>
                          </div>
                          <div className="input-container">
                            <div><label>Live</label></div>
                            <div className="checkbox-toggle">
                              <input className="checkbox-toggle__input" name="live" id="live" type="checkbox" onChange={this.prepareElectionCheckbox.bind(this)} defaultChecked={election.live} />
                              <label htmlFor="live" className="checkbox-toggle__label" />
                            </div>
                          </div>
                        </form>
                      </div>
                    );
                  })}
                </section>
              <section className="settings-section">
                <div className="section-header">
                  <label id="settings-label">Choices</label>
                  {this.state.canSaveChoice ? (
                    <div className="save-btn" id="save" onClick={this.saveChoice.bind(this)}>
                      <div>{saveEmoji()}</div>
                    </div>
                  ) : (
                    <span></span>
                  )}
                  {(this.state.canAddChoice && !this.state.canSaveChoice) ? (
                    <div id="add-press-release-btn" onClick={this.addChoice.bind(this)}>Add Choice</div>
                  ) : (
                    <span></span>
                  )}
                </div>
                {choices ? (
                  <div>
                  {
                    choices.map((choice, i) => {
                      console.log(choice);
                      return (
                        <div className="election-details">
                          <form>
                            <div className="input-container">
                              <div><label>{`Name (${choice.count})`}</label></div>
                              <input name={`name_${choice.uuid}`} id="title-input" data-id={choice.uuid} defaultValue={choice.name} onChange={this.prepareChoiceInput.bind(this)}></input>
                            </div>
                          </form>
                        </div>
                      );
                    })
                  }
                  </div>
                ) : (
                  <span/>
                )}
              </section>
              </div>
            ) : (
              <div className="loader" />
            )}
          </div>
        <div className="spacer" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const shopLoaded = fromJS(store.getState().getIn(['shop', 'shopLoaded']));
  const elections = fromJS(store.getState().getIn(['shop', 'elections'])).toJS();
  return { elections: elections, loaded: shopLoaded}
}

export default connect(mapStateToProps)(Voting);
