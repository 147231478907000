import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import store from '../store';
import { isLoggedIn } from '../utils/Config';

class Landing extends Component {
  render() {
    let loggedIn = isLoggedIn();
    return (
      <div id="Landing">
        {loggedIn ? (
          <Link to="/control-tower">
            <div id="user-btn">
              <div id="howdy">🏠</div>
            </div>
          </Link>
        ) : (
          <Link to="/login">
            <div id="user-btn">
              <div id="got-the-key-can-you-unlock-it">🔑</div>
            </div>
          </Link>
        )}
        <div id="hero-logo-container">
          <img id="hero-logo" alt="Hero Logo" src="/h-mark.svg"/>
        </div>
      </div>
    );
  }
}

export default Landing;
