import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import reducer from './reducers';
import logger from 'redux-logger';

// Redux debug in Dev use:
import { composeWithDevTools } from 'redux-devtools-extension';
const middleware = composeWithDevTools(applyMiddleware(promise, thunk, logger));

// Redux prod use:
// const middleware = applyMiddleware(promise(), thunk);


export default createStore(reducer, middleware);
