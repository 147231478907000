import axios from 'axios';
import store from '../store';
import { headers, formHeaders, checkAuth } from '../utils/Config';

export function fetchShopStats() {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'GET',
    url: `/api/v1/admin/?t=${buster}`,
    responseType: 'json',
    data: {}
  }).then((response) => {
    let userCount = JSON.parse(JSON.stringify(response.data.user_count));
    let orderCount = JSON.parse(JSON.stringify(response.data.order_count));
    store.dispatch({ type: "SET_STATS", userCount: userCount, orderCount: orderCount });
    return { status: response.status, data: response.data };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function fetchShopInfo() {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'GET',
    url: `/api/v1/admin/shop?t=${buster}`,
    responseType: 'json',
    data: {}
  }).then((response) => {
    store.dispatch({ type: "SET_SHOP_INFO", shop: response.data.shop, elections: response.data.elections, locations: response.data.locations });
    return { status: response.status, data: response.data };
  }).catch(function (error) {
    console.log(error);
  });
}

export function update(data) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'PATCH',
    url: `/api/v1/admin/shop?t=${buster}`,
    responseType: 'json',
    data: { shop: data }
  }).then((response) => {
    store.dispatch({ type: "SET_SHOP_INFO", shop: response.data.shop });
    return { status: response.status, data: response.data };
  }).catch(function (error) {
    console.log(error);
  });
}

export function deleteFaq(id) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    data: { faq_id: id },
    url: `/api/v1/admin/remove-faq?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchShopInfo();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function addFaq() {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: {},
    url: `/api/v1/admin/add-faq/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchShopInfo();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function updateFaqs(data) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: {faqs: data},
    url: `/api/v1/admin/update-faqs/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchShopInfo();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function createPressRelease() {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: {},
    url: `/api/v1/admin/add-press-release/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchShopInfo();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function addReleaseImage(id, image) {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  let formData = new FormData();
  formData.append("image", image);
  formData.append("press_release_id", id);
  instance.request({
    method: 'POST',
    data: formData,
    url: `/api/v1/admin/add-press-release-image/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchShopInfo();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}


export function saveRelease(data) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'PATCH',
    data: {press_releases: data},
    url: `/api/v1/admin/save-press-release/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchShopInfo();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function updateMediaKit(data) {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  let formData = new FormData();
  formData.append("media_kit", data.media_kit);
  instance.request({
    method: 'PATCH',
    data: formData,
    url: `/api/v1/admin/update-media-kit/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchShopInfo();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function deleteRelease(id) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    data: { press_release_id: id },
    url: `/api/v1/admin/remove-press-release?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchShopInfo();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function saveElection(data) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'PATCH',
    data: { election_data: data },
    url: `/api/v1/admin/save-election/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchShopInfo();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function createChoice() {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    url: `/api/v1/admin/add-choice/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchShopInfo();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}


export function saveChoices(data) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: { choices: data },
    url: `/api/v1/admin/save-choices/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchShopInfo();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function saveLocations(file) {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  let formData = new FormData();
  formData.append("file", file);
  instance.request({
    method: 'POST',
    data: formData,
    url: `/api/v1/admin/update-locations/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchShopInfo();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}
export function syncLocations() {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    url: `/api/v1/admin/sync-locations/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchShopInfo();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function downloadRequests() {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'GET',
    url: `/api/v1/admin/download-requests/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
  });
}
