import axios from 'axios';
import store from '../store';
import {headers, formHeaders, checkAuth} from '../utils/Config';

// Get product list from hero.co API
export function fetchProducts() {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'GET',
    url: '/api/v1/admin/products/?t=' + buster,
    responseType: 'json',
    data: {}
  }).then((response) => {
    console.log("RESPONSE PRODUCTS");
    console.log(response);
    let products = JSON.parse(JSON.stringify(response.data.products));
    store.dispatch({ type: "SET_PRODUCTS", products: products });
    return { status: response.status, data: response.data };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function loadingProducts() {
  store.dispatch({type: "LOADING_PRODUCTS"});
}


// Sync products from Shopify via hero.co API
export function sync() {
  console.log("sync Products");
  // POST to sync
  // After request resolves, fetchProducts()
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    url: '/api/v1/admin/sync-products/?t=' + buster,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchProducts();
    return { status: response.status};
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}


export function setCurrentProduct(id) {
  store.dispatch({type: "SET_CURRENT_PRODUCT", id: id})
}


export function addImage(id, image) {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  let formData = new FormData();
  formData.append("image", image);
  instance.request({
    method: 'POST',
    data: formData,
    url: `/api/v1/admin/products/${id}/image/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchProducts();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}


export function removeImage(productId, imageId) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    data: {image_id: imageId},
    url: `/api/v1/admin/products/${productId}/image/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchProducts();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function update(productId, data) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'PATCH',
    data: { product: data },
    url: `/api/v1/admin/products/${productId}/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchProducts();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function updateVariantStock(productId, data) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'PATCH',
    data: { variants: data },
    url: `/api/v1/admin/products/${productId}/variants?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchProducts();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function updateFaqs(id, data) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: {faqs: data},
    url: `/api/v1/admin/products/${id}/update-faqs/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchProducts();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function addFaq(id) {
  let instance = axios.create(formHeaders());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'POST',
    data: {},
    url: `/api/v1/admin/products/${id}/add-faq/?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchProducts();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}

export function deleteFaq(productId, id) {
  let instance = axios.create(headers());
  let buster = Math.round(new Date().getTime() / 1000);
  instance.request({
    method: 'DELETE',
    data: { faq_id: id },
    url: `/api/v1/admin/products/${productId}/remove-faq?t=${buster}`,
    responseType: 'json'
  }).then((response) => {
    console.log(response);
    fetchProducts();
    return { status: response.status };
  }).catch(function (error) {
    console.log(error);
    checkAuth(error);
  });
}