import React, { Component } from 'react';
import { Link } from "react-router-dom";
import FlipNumbers from 'react-flip-numbers';
import store from '../store';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { fetchShopStats } from '../actions/shopActions';

class ControlTower extends Component {
  state = {
    timeRemaining: 10000
  };
  componentDidMount() {
    this.timer = setInterval(() => {
      fetchShopStats();
    }, 4000);
  }
  componentWillMount() {
    fetchShopStats();
    store.dispatch({ type: "SET_CURRENT_ACHIEVEMENT", id: null });
  }
  componentWillUnmount() {
    clearInterval(this.timer);
  }
  render() {
    return (
      <div id="ControlTower">
        <div id="hero-logo-container">
          <Link to="/">
            <img id="hero-logo" alt="Hero Logo" src="/h-mark.svg" />
          </Link>
        </div>
        <div id="menu">
          {/* <Link to="/settings">
            <div className="menu-card">
              <div className="menu-details">Settings</div>
              <img id="hero-config" alt="Hero Config" src="/settings.png" />
            </div>
          </Link> */}
          {/* <Link to="/voting">
            <div className="menu-card">
              <div className="menu-details">Voting</div>
              <img id="hero-voting" alt="Hero Voting" src="/vote.png" />
            </div>
          </Link> */}
          <Link to="/locations">
            <div className="menu-card">
              <div className="menu-details">Locations</div>
              <img id="hero-locations" alt="Hero Locations" src="/location.png" />
            </div>
          </Link>
          <Link to="/retail-requests">
            <div className="menu-card">
              <div className="menu-details">Retail Requests</div>
              <img id="hero-requests" alt="Retail Requests" src="/retail.png" />
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const userCount = store.getState().getIn(['shop', 'userCount']);
  const orderCount = store.getState().getIn(['shop', 'orderCount']);
  return { userCount: userCount, orderCount: orderCount}
}

export default connect(mapStateToProps)(ControlTower);

