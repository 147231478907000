import React, { Component } from 'react';
import store from '../store';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { setCurrentProduct, addImage, removeImage, update, updateVariantStock, addFaq, updateFaqs, deleteFaq} from '../actions/productActions';
import { baseURL, saveEmoji } from '../utils/Config';

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.currentProduct,
      fieldsToUpdate: {},
      variantStockToUpdate: {},
      faqFieldsToUpdate: {},
      canSave: false,
      canSaveFaqs: false,
      canAddFaq: true,
      showBasicInfo: true,
      showAssets: false,
      showNutritionals: false,
      showOptions: false,
      showFAQs: false,
      showFAQ1: false,
      showFAQ2: false,
      showFAQ3: false,
      showFAQ4: false,
      showFAQ5: false,
      showFAQ6: false,
      showFAQ7: false,
      showFAQ8: false
    };
  }
  componentWillMount() {
    const id = this.props.match.params.id;
    this.setState({id: id});
    setCurrentProduct(id);
  }
  componentDidUpdate() {
    console.log("NEW STATE");
    console.log(this.state);
    console.log(this.state.variantStockToUpdate);
  }
  prepareInput(event) {
    const { target: { name, value } } = event
    this.setState({
      fieldsToUpdate: Object.assign(
        {},
        this.state.fieldsToUpdate,
        { [name]: value }
      ),
      canSave: true
    });
  }
  prepareCheckbox(event) {
    const { target: { name, checked } } = event
    this.setState({
      fieldsToUpdate: Object.assign(
        {},
        this.state.fieldsToUpdate,
        { [name]: checked }
      ),
      canSave: true
    });
  }
  prepareVariantStockCheckbox(event) {
    const { target: { checked, name } } = event
    const id = event.target.dataset.id
    this.setState({
      variantStockToUpdate: Object.assign(
        {},
        this.state.variantStockToUpdate,
        { [name]: checked }
      ),
      canSaveVariant: true
    });
  }
  prepareFaqInput(event) {
    const { target: { name, value } } = event;
    const faqId = event.target.dataset.faq_id;
    console.log("preparing FAQ data for save");
    console.log("name");
    console.log(name);
    console.log("val");
    console.log(value);
    console.log("faqId");
    console.log(faqId);
    this.setState({
      faqFieldsToUpdate: Object.assign(
        {},
        this.state.faqFieldsToUpdate,
        { [name]: value }
      ),
      canSaveFaqs: true,
      canAddFaq: false
    });
  }
  saveFaqs() {
    console.log(this.state.faqFieldsToUpdate);
    updateFaqs(this.state.id, this.state.faqFieldsToUpdate);
    this.setState({canSaveFaqs: false, canAddFaq: true});
  }
  removeFaq(event) {
    const id = event.target.dataset.id;
    deleteFaq(this.state.id, id);
  }
  addProductFaq() {
    let productId = this.state.id;
    addFaq(productId);
  }
  prepareImage(event) {
    const files = event.target.files;
    const id = event.target.dataset.id;
    addImage(id, files[0]);
  }
  deleteImage(event) {
    console.log("deleting");
    console.log(event);
    const productId = event.target.dataset.product_id;
    const imageId = event.target.dataset.image_id;
    removeImage(productId, imageId);
  }
  save() {
    const fieldsToUpdate = this.state.fieldsToUpdate;
    const productId = this.state.id;
    update(productId, fieldsToUpdate);
    this.setState({canSave: false});
  }
  saveVariants() {
    const fieldsToUpdate = this.state.variantStockToUpdate;
    const productId = this.state.id;
    updateVariantStock(productId, fieldsToUpdate);
    this.setState({canSaveVariant: false});
  }
  openCloseFAQ(event) {
    let faqNumber = event.target.dataset.faq;
    let key = `showFAQ${faqNumber}`;
    let faqState = this.state[key];
    this.setState({[key] : !faqState});
  }
  openNavItem(section) {
    if (section === "basic") {
      this.setState({ showBasicInfo: true, showAssets: false, showNutritionals: false, showFAQs: false, showOptions: false, canSave: false});
    } else if (section === "assets") {
      this.setState({ showBasicInfo: false, showAssets: true, showNutritionals: false, showFAQs: false, showOptions: false, canSave: false });
    } else if (section === "nutritionals") {
      this.setState({ showBasicInfo: false, showAssets: false, showNutritionals: true, showFAQs: false, showOptions: false, canSave: false });
    } else if (section === "faqs") {
      this.setState({ showBasicInfo: false, showAssets: false, showNutritionals: false, showFAQs: true, showOptions: false, canSave: false });
    } else if (section === "options") {
      this.setState({ showBasicInfo: false, showAssets: false, showNutritionals: false, showFAQs: false, showOptions: true, canSave: false });
    }
  }
  render() {
    const loaded = this.props.productsLoaded;
    const id = this.state.id;
    let product;
    let images;
    let variants;
    let faqs;
    let base = baseURL();
    if (loaded) {
      product = this.props.products.find(x => x.uuid === this.props.currentProduct);
      if (product) {
        images = product.images;
        variants = product.variants;
        faqs = product.faqs;
      }
    }
    console.log("product");
    console.log(product);
    return (
      <div name="Product" id="product">
        <div id="hero-logo-container">
          <Link to="/control-tower">
            <img id="hero-logo" alt="Hero Logo" src="/h-mark.svg" />
          </Link>
        </div>
        { loaded ? (
          <div id="page-nav">
            {this.state.showBasicInfo ? (
              <div onClick={this.openNavItem.bind(this, "basic")} className="active-nav-btn">Basic Info</div>
            ) : (
              <div onClick={this.openNavItem.bind(this, "basic")} className="nav-btn">Basic Info</div>
            )}
            {this.state.showOptions ? (
              <div onClick={this.openNavItem.bind(this, "options")} className="active-nav-btn">Options</div>
            ) : (
              <div onClick={this.openNavItem.bind(this, "options")} className="nav-btn">Options</div>
            )}
            {this.state.showAssets ? (
              <div onClick={this.openNavItem.bind(this, "assets")} className="active-nav-btn">Assets</div>
            ) : (
              <div onClick={this.openNavItem.bind(this, "assets")} className="nav-btn">Assets</div>
            )}
            {this.state.showNutritionals ? (
              <div onClick={this.openNavItem.bind(this, "nutritionals")} className="active-nav-btn">Nutritionals</div>
            ) : (
              <div onClick={this.openNavItem.bind(this, "nutritionals")} className="nav-btn">Nutritionals</div>
            )}
            {this.state.showFAQs ? (
              <div onClick={this.openNavItem.bind(this, "faqs")} className="active-nav-btn">FAQs</div>
            ) : (
              <div onClick={this.openNavItem.bind(this, "faqs")} className="nav-btn">FAQs</div>
            )}
          </div>
        ) : (
          <span></span>
        )}

        {loaded ? (
          <div id="product-container">
            { product ? (
              <div id="actions">
              </div>
            ) : (
              <span></span>
            )}
            { product ? (
              <div id="product-info">
                {/* BASIC INFO */}
                {this.state.showBasicInfo ? (
                  <section className="product-section">
                    <div className="section-header">
                      <label id="product-label">Basic Info</label>
                      <a href={`https://hero-foods.myshopify.com/admin/products/${product.shopify_id}`} target="_blank">
                        <div className="external-link"><div>🔗</div></div>
                      </a>
                      {this.state.canSave ? (
                        <div className="save-btn" id="save" onClick={this.save.bind(this)}>
                          <div>{saveEmoji()}</div>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <div className="product-details">
                      <div id="basic-info">
                        <form>
                          <div className="split-input">
                            <div className="split-left">
                              <div className="input-container">
                                <div><label>Title</label></div>
                                <input name="title" id="product-title-input" defaultValue={product.title} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Description</label></div>
                                <textarea name="description" id="product-description-input" defaultValue={product.description} onChange={this.prepareInput.bind(this)}></textarea>
                              </div>
                              <div className="input-container">
                                <div><label>Sub Description</label></div>
                                <textarea name="sub_description" id="product-sub-description-input" defaultValue={product.sub_description} onChange={this.prepareInput.bind(this)}></textarea>
                              </div>
                              <div className="input-container">
                                <div><label>Slug</label></div>
                                <input name="slug" id="product-slug-input" defaultValue={product.slug} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Ingredients</label></div>
                                <textarea name="ingredients" id="product-ingredients-input" defaultValue={product.ingredients} onChange={this.prepareInput.bind(this)}></textarea>
                              </div>
                              <div className="input-container">
                                <div><label>Allergen Info</label></div>
                                <textarea name="allergen_info" id="product-allergen-info-input" defaultValue={product.allergen_info} onChange={this.prepareInput.bind(this)}></textarea>
                              </div>
                              { product.display_pricing ? (
                                <span></span>
                              ) : (
                                <div className="input-container">
                                  <div><label>Pricing Description</label></div>
                                  <input name="pricing_description" id="product-pricing_description-input" defaultValue={product.pricing_description} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              )}
                            </div>
                            <div className="split-right">
                            <div className="input-container">
                                <div><label>Active</label></div>
                                <div className="checkbox-toggle">
                                  <input className="checkbox-toggle__input" name="active" id="active" type="checkbox" onChange={this.prepareCheckbox.bind(this)} defaultChecked={product.active}/>
                                  <label htmlFor="active" className="checkbox-toggle__label"/>
                                </div>
                              </div>
                              <div className="input-container">
                                <div><label>Visible</label></div>
                                <div className="checkbox-toggle">
                                  <input className="checkbox-toggle__input" name="visible" id="visible" type="checkbox" onChange={this.prepareCheckbox.bind(this)} defaultChecked={product.visible}/>
                                  <label htmlFor="visible" className="checkbox-toggle__label"/>
                                </div>
                              </div>
                              <div className="input-container">
                                <div><label>Display on Landing</label></div>
                                <div className="checkbox-toggle">
                                  <input className="checkbox-toggle__input" name="display_on_landing" id="display_on_landing" type="checkbox" onChange={this.prepareCheckbox.bind(this)} defaultChecked={product.display_on_landing}/>
                                  <label htmlFor="display_on_landing" className="checkbox-toggle__label" />
                                </div>
                              </div>
                              <div className="input-container">
                                <div><label>Display Pricing</label></div>
                                <div className="checkbox-toggle">
                                  <input className="checkbox-toggle__input" name="display_pricing" id="display_pricing" type="checkbox" onChange={this.prepareCheckbox.bind(this)} defaultChecked={product.display_pricing}/>
                                  <label htmlFor="display_pricing" className="checkbox-toggle__label" />
                                </div>
                              </div>
                              <div className="input-container">
                                <div><label>In Stock</label></div>
                                <div className="checkbox-toggle">
                                  <input className="checkbox-toggle__input" name="in_stock" id="in_stock" type="checkbox" onChange={this.prepareCheckbox.bind(this)} defaultChecked={product.in_stock}/>
                                  <label htmlFor="in_stock" className="checkbox-toggle__label" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </section>
                ) : (
                  <span></span>
                )}
                {/* END BASIC INFO */}
                {/* OPTIONS */}
                {this.state.showOptions ? (
                  <section className="product-section">
                    <div className="section-header">
                      <label id="product-label">Options</label>
                      {this.state.canSaveVariant ? (
                        <div className="save-btn" id="save" onClick={this.saveVariants.bind(this)}>
                          <div>{saveEmoji()}</div>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    {variants ? (
                      <div id="variants">
                        { variants.map((variant, i) => {
                          return (
                            <div className="product-details">
                              <div className="product-options">
                                <form>
                                  <div className="split-input">
                                    <div className="split-left">
                                      {variant.option_1_name ? (
                                        <div className="option-info">
                                          <div className="option-info-label">{variant.option_1_name}: </div>
                                          <div className="option-info-value">{variant.option_1_value}</div>
                                        </div>
                                      ) : (
                                        <span></span>
                                      )}
                                      {variant.option_2_name ? (
                                        <div className="option-info">
                                          <div className="option-info-label">{variant.option_2_name}: </div>
                                          <div className="option-info-value">{variant.option_2_value}</div>
                                        </div>
                                      ) : (
                                        <span></span>
                                      )}
                                      {variant.option_3_name ? (
                                        <div className="option-info">
                                          <div className="option-info-label">{variant.option_3_name}: </div>
                                          <div className="option-info-value">{variant.option_3_value}</div>
                                        </div>
                                      ) : (
                                        <span></span>
                                      )}
                                      <div className="option-info">
                                        <div className="option-info-label">Price: </div>
                                        <div className="option-info-value">${variant.price}</div>
                                      </div>
                                      <div className="option-info">
                                        <div className="option-info-label">Inventory: </div>
                                        <div className="option-info-value">{variant.inventory_quantity}</div>
                                      </div>
                                    </div>
                                    <div className="split-right">
                                      <div className="input-container">
                                        <div><label>In Stock</label></div>
                                        <div className="checkbox-toggle">
                                          <input className="checkbox-toggle__input" name={variant.uuid} id={variant.uuid} type="checkbox" data-id={variant.uuid} onChange={this.prepareVariantStockCheckbox.bind(this)} defaultChecked={variant.in_stock} />
                                          <label htmlFor={variant.uuid} className="checkbox-toggle__label" />
                                        </div>
                                      </div>
                                      <a href={`https://hero-foods.myshopify.com/admin/products/${product.shopify_id}/variants/${variant.shopify_id}`} target="_blank">
                                        <div className="external-link"><div>🔗</div></div>
                                      </a>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <span></span>
                    )}
                  </section>
                ) : (
                  <span></span>
                )}
                {/* END OPTIONS */}
                {/* ASSETS */}
                {this.state.showAssets ? (
                  <section className="product-section">
                    <div className="section-header">
                      <label id="product-label">Assets</label>
                      {this.state.canSave ? (
                        <div className="save-btn" id="save" onClick={this.save.bind(this)}>
                          <div>{saveEmoji()}</div>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <div className="product-details">
                      <div id="assets">
                        <form>
                          <div className="full-input">
                            <div className="input-container">
                              <div><label>3D File URL</label></div>
                              <input name="three_dimensional_asset_url" id="product-3d-url-input" defaultValue={product.three_dimensional_asset_url} onChange={this.prepareInput.bind(this)}></input>
                            </div>
                          </div>
                          <div className="full-input">
                            <div className="input-container">
                              <div className="sub-section-title">
                                <label>Gallery Images</label>
                                <input type="file" id="gallery-image" data-id={id} onChange={this.prepareImage} />
                                <label id="file-upload-btn" htmlFor="gallery-image">Add +</label>
                              </div>
                            </div>
                            {images ? (
                              <div id="product-images">
                                { images.map((image, i) => {
                                  let url = image.url;
                                  let uuid = image.uuid;
                                  let imageStyle = {
                                    backgroundImage: `url(${url})`
                                  };
                                  return (
                                    <div className="image-container">
                                      <div style={imageStyle} className="product-image"></div>
                                      <div data-product_id={id} data-image_id={uuid} className="delete-btn" onClick={this.deleteImage.bind(this)}>Delete</div>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              <span/>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </section>
                ) : (
                  <span></span>
                )}
                {/* END ASSETS */}
                {/* NUTRITIONAL INFO */}
                {this.state.showNutritionals ? (
                  <section className="product-section">
                    <div className="section-header">
                      <label id="product-label">Nutritionals</label>
                      {this.state.canSave ? (
                        <div className="save-btn" id="save" onClick={this.save.bind(this)}>
                          <div>{saveEmoji()}</div>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <div className="product-details">
                      <div id="nutritional-info">
                        <form>
                          <div className="split-input">
                            <div className="split-left">
                              <div className="input-container">
                                <div><label>Net Carbs</label></div>
                                <input name="net_carbs" id="product-net-carbs-input" defaultValue={product.net_carbs} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Serving Size</label></div>
                                <input name="serving_size" id="product-serving-size-input" defaultValue={product.serving_size} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Total Fat</label></div>
                                <input name="total_fat" id="product-total-fat-input" defaultValue={product.total_fat} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Saturated Fat</label></div>
                                <input name="saturated_fat" id="product-saturated-fat-input" defaultValue={product.saturated_fat} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Cholesterol</label></div>
                                <input name="cholesterol" id="product-cholesterol-input" defaultValue={product.cholesterol} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Sodium</label></div>
                                <input name="sodium" id="product-sodium-input" defaultValue={product.sodium} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Total Carbohydrates</label></div>
                                <input name="total_carbohydrates" id="product-total-carbohydrates-input" defaultValue={product.total_carbohydrates} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Dietary Fiber</label></div>
                                <input name="dietary_fiber" id="product-dietary-fiber-input" defaultValue={product.dietary_fiber} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Protein</label></div>
                                <input name="protein" id="product-protein-input" defaultValue={product.protein} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Added Sugars</label></div>
                                <input name="added_sugars" id="product-added-sugars-input" defaultValue={product.added_sugars} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Vitamin D</label></div>
                                <input name="vitamin_d" id="product-vitamin-d-input" defaultValue={product.vitamin_d} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Calcium</label></div>
                                <input name="calcium" id="product-calcium-input" defaultValue={product.calcium} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Iron</label></div>
                                <input name="iron" id="product-iron-input" defaultValue={product.iron} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Potassium</label></div>
                                <input name="potassium" id="product-potassium-input" defaultValue={product.potassium} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                            </div>
                            <div className="split-right">
                              <div className="input-container">
                                <div><label>Calories</label></div>
                                <input name="calories" id="product-calories-input" defaultValue={product.calories} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Servings Per Container</label></div>
                                <input name="servings_per_container" id="product-servings-per-container-input" defaultValue={product.servings_per_container} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Total Fat DV</label></div>
                                <input name="total_fat_dv" id="product-total-fat-dv-input" defaultValue={product.total_fat_dv} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Saturated Fat DV</label></div>
                                <input name="saturated_fat_dv" id="product-saturated-fat-dv-input" defaultValue={product.saturated_fat_dv} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Cholesterol DV</label></div>
                                <input name="cholesterol_dv" id="product-cholesterol-dv-input" defaultValue={product.cholesterol_dv} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Sodium DV</label></div>
                                <input name="sodium_dv" id="product-sodium-dv-input" defaultValue={product.sodium_dv} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Total Carbohydrates DV</label></div>
                                <input name="total_carbohydrates_dv" id="product-total-carbohydrates-dv-input" defaultValue={product.total_carbohydrates_dv} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Dietary Fiber DV</label></div>
                                <input name="dietary_fiber_dv" id="product-dietary-fiber-dv-input" defaultValue={product.dietary_fiber_dv} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Total Sugars</label></div>
                                <input name="total_sugars" id="product-total-sugars-input" defaultValue={product.total_sugars} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Added Sugars DV</label></div>
                                <input name="added_sugars_dv" id="product-added-sugars-dv-input" defaultValue={product.added_sugars_dv} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Vitamin D DV</label></div>
                                <input name="vitamin_d_dv" id="product-vitamin-d-dv-input" defaultValue={product.vitamin_d_dv} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Calcium DV</label></div>
                                <input name="calcium_dv" id="product-calcium-dv-input" defaultValue={product.calcium_dv} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Iron DV</label></div>
                                <input name="iron_dv" id="product-iron-dv-input" defaultValue={product.iron_dv} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Potassium DV</label></div>
                                <input name="potassium_dv" id="product-potassium-dv-input" defaultValue={product.potassium_dv} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </section>
                ) : (
                  <span></span>
                )}
                {/* END NUTRITIONAL INFO */}
                {/* FAQs */}
                {this.state.showFAQs ? (
                  <section className="drop-section">
                    <div className="faqs-header">
                      <div className="stick-left">
                        <label>FAQs</label>
                      </div>
                      {this.state.canSaveFaqs ? (
                        <div className="save-btn" id="save-faqs" onClick={this.saveFaqs.bind(this)}>
                          <div>{saveEmoji()}</div>
                        </div>
                      ) : (
                        <span></span>
                      )}
                      {this.state.canAddFaq ? (
                        <div id="add-faq-btn" onClick={this.addProductFaq.bind(this)}>Add FAQ</div>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    {faqs ? (
                      <div id="faqs-container">
                        { faqs.map((faq, i) => {
                          let position = faq.position;
                          let numberFormatted = `#${position}`;
                          let questionFieldName = `faq_${position}_question`;
                          let answerFieldName = `faq_${position}_answer`;
                          return (
                          <div className="faq-section">
                            <div className="drop-details">
                            <label className="faq-label">{numberFormatted}</label> 
                            <div id="basic-info">
                              <form>
                                {/* FAQ */}
                                <div className="qa-container">
                                  <div className="full-input">
                                    <div className="input-container">
                                      <div><label>Question</label></div>
                                      <input name={questionFieldName} id={questionFieldName} data-faq_id={faq.uuid} defaultValue={faq.question} onChange={this.prepareFaqInput.bind(this)}></input>
                                    </div>
                                    <div className="input-container">
                                      <div><label>Answer</label></div>
                                        <textarea name={answerFieldName} id={answerFieldName} data-faq_id={faq.uuid} defaultValue={faq.answer} onChange={this.prepareFaqInput.bind(this)}></textarea>
                                    </div>
                                  </div>
                                  <img alt="trash faq" data-id={faq.uuid} className="trash-faq" src="/bin.png" onClick={this.removeFaq.bind(this)} />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        );
                        })}
                      </div>
                    ) : (
                      <span>
                      </span>
                    )}
                  </section>
                ) : (
                  <span></span>
                )}
                {/* END FAQs */}
              </div>
            ): (
              <div id="product-missing">
                Product not found ☹️
              </div>
            )}
          </div>
        ): (
          <div className = "loader"></div>
        )}
      <div className="spacer"/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const products = fromJS(store.getState().getIn(['shop', 'products'])).toJS();
  const productsLoaded = fromJS(store.getState().getIn(['shop', 'productsLoaded']));
  const currentProduct = fromJS(store.getState().getIn(['shop', 'currentProduct']));
  return { products: products, productsLoaded: productsLoaded, currentProduct: currentProduct }
}

export default connect(mapStateToProps)(Product);
