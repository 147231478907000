import React, { Component } from 'react';
import store from '../store';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { fetchUsers } from '../actions/userActions';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentWillMount() {
    fetchUsers();
  }
  render() {
    const users = this.props.users;
    const loaded = this.props.loaded;
    return (
      <div id="Users">
        <div id="hero-logo-container">
          <Link to="/control-tower">
            <img id="hero-logo" alt="Hero Logo" src="/h-mark.svg" />
          </Link>
        </div>
        { loaded ? (
          <Link to="/new-user">
            <div id="new-user-btn"><div>Create User</div></div>
          </Link>
        ) : (
          <span></span>
        )}
        { loaded ? (
          <section id="users-container">
            <label>Users</label>
            { users.length? ( 
              <div id="users">
                {
                  users.map((user, i) => {
                    let uuid = user.uuid;
                    let email = user.email;
                    let phone = user.phone;
                    let displayText;
                    if (email) {
                      displayText = email;
                    } else {
                      displayText = phone;
                    }
                    return (
                      <Link to={`/users/${uuid}`} key={i}>
                        <div className="user" key={i}>
                          <div className="user-details">
                            <div className="phone-number">{displayText}</div>
                          </div>
                        </div>
                      </Link>
                    );
                  })
                }
              </div>
            ) : (
              <div id="no-users">No Users ☹️</div>
            )}
          </section>
        ) : (
          <div className="loader"></div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const users = fromJS(store.getState().getIn(['app', 'users'])).toJS();
  const usersLoaded = fromJS(store.getState().getIn(['app', 'usersLoaded']));
  return { users: users, loaded: usersLoaded }
}

export default connect(mapStateToProps)(Users);

