import React, { Component } from 'react';
import store from '../store';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { baseURL, saveEmoji } from '../utils/Config';
import { fetchUser, setCurrentUser, updateUser, updateUserAchievements, removeFromDrop } from '../actions/userActions';
import { fetchDrops } from '../actions/dropActions';

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.currentUser,
      userDropId: null,
      fieldsToUpdate: {},
      unlockedAchievementFieldsToUpdate: {},
      submissionAchievementFieldsToUpdate: {},
      canSave: false,
      canSaveAchievements: false,
      viewingDrops: false,
      viewingDrop: false,
      showBasicInfo: true,
      showDrops: false,
      showOrders: false
    };
  }
  componentWillMount() {
    const id = this.props.match.params.id;
    this.setState({ id: id });
    setCurrentUser(id);
    fetchDrops();
    fetchUser(id);
  }
  componentDidUpdate() {
    console.log("new state");
    console.log(this.state);
  }
  prepareInput(event) {
    const { target: { name, value } } = event
    this.setState({
      fieldsToUpdate: Object.assign(
        {},
        this.state.fieldsToUpdate,
        { [name]: value }
      ),
      canSave: true
    });
  }
  prepareAchievementCheckbox(event) {
    const { target: { name, checked } } = event
    const id = event.target.dataset.id;
    this.setState(prevState => ({
      unlockedAchievementFieldsToUpdate: {
        ...prevState.unlockedAchievementFieldsToUpdate,
        [id]: { 
          ...prevState[id],
          [name]: checked
        }
      }
    }));
    this.setState({canSaveAchievements: true});
  }
  prepareAchievementInput(event) {
    const { target: { name, value } } = event;
    const id = event.target.dataset.id;
    this.setState(prevState => ({
      submissionAchievementFieldsToUpdate: {
        ...prevState.submissionAchievementFieldsToUpdate,
        [id]: { 
          ...prevState[id],
          [name]: value
        }
      }
    }));
    this.setState({canSaveAchievements: true});
  }
  saveUser() {
    this.setState({canSave: false, viewingDrops: false});
    updateUser(this.state.id, this.state.fieldsToUpdate)
  }
  saveUserAchievements() {
    this.setState({canSaveAchievements: false});
    let data = {};
    data["unlocked"] = this.state.unlockedAchievementFieldsToUpdate;
    data["submission"] = this.state.submissionAchievementFieldsToUpdate;
    updateUserAchievements(this.state.id, data)
  }
  viewDropOptions() {
    this.setState({viewingDrops: true});
  }
  cancelDropOptions() {
    this.setState({ viewingDrops: false });
  }
  addUserToDrop(drop) {
    this.setState({
      fieldsToUpdate: Object.assign(
        {},
        this.state.fieldsToUpdate,
        { ["drop_uuid"]: drop.value }
      ),
      canSave: true
    });
  }
  removeUserFromDrop() {
    let userId = this.state.id;
    let dropId = this.state.userDropId;
    removeFromDrop(userId, dropId);
  }
  viewUserDrop(event) {
    let id = event.target.dataset.id;
    this.setState({ userDropId: id, viewingDrop: true, viewingDrops: false, showDrops: false });
  }
  openNavItem(section) {
    if (section === "basic") {
      this.setState({ showBasicInfo: true, showDrops: false, viewingDrop: false, viewingDrops: false, showOrders: false, canSave: false });
    } else if (section === "drops") {
      this.setState({ showBasicInfo: false, showDrops: true, viewingDrop: false, viewingDrops: false, showOrders: false, canSave: false });
    } else if (section === "orders") {
      this.setState({ showBasicInfo: false, showDrops: false, viewingDrop: false, viewingDrops: false, showOrders: true, canSave: false });
    }
  }
  render() {
    let loaded = this.props.userLoaded;
    const user = this.props.user;
    const drops = null;
    const orders = null;
    const userDropId = this.state.userDropId;
    let userDrop;
    let userDropAchievements;
    let allDrops = this.props.drops;
    let userDrops;
    let dropOptions = [];
    if (allDrops) {
      allDrops.map((drop, i) => {
        let title = drop.title;
        let optionId = drop.uuid;
        dropOptions.push({ value: optionId, label: title });
      });
    }
    if (user) {
      userDrops = user.user_drops;
      if (userDropId) {
        userDrop = userDrops.find(x => x.uuid === userDropId);
        if (userDrop) {
          userDropAchievements = userDrop.user_achievements;
          console.log("userDropAchievements");
          console.log(userDropAchievements);
          console.log("userDrop");
          console.log(userDrop);
        }
      }
    }
    let selectedDropOption;
    console.log(user);
    return (
      <div name="User" id="user">
        <div id="hero-logo-container">
          <Link to="/control-tower">
            <img id="hero-logo" alt="Hero Logo" src="/h-mark.svg" />
          </Link>
        </div>
        { (loaded && user) ? (
          <div id="page-nav">
            {this.state.showBasicInfo ? (
              <div onClick={this.openNavItem.bind(this, "basic")} className="active-nav-btn">Info</div>
            ) : (
              <div onClick={this.openNavItem.bind(this, "basic")} className="nav-btn">Info</div>
            )}
            {this.state.showDrops || this.state.viewingDrop ? (
              <div onClick={this.openNavItem.bind(this, "drops")} className="active-nav-btn">Drops</div>
            ) : (
              <div onClick={this.openNavItem.bind(this, "drops")} className="nav-btn">Drops</div>
            )}
            {this.state.showOrders ? (
              <div onClick={this.openNavItem.bind(this, "orders")} className="active-nav-btn">Orders</div>
            ) : (
              <div onClick={this.openNavItem.bind(this, "orders")} className="nav-btn">Orders</div>
            )}
          </div>
        ) : (
          <span></span>
        )}
        {loaded ? (
          <div id="user-container">
            {/* INFO */}
            { this.state.showBasicInfo ? (
              <section className="user-section">
                <div className="section-header">
                  <div className="stick-left">
                    <label>Info</label>
                  </div>
                  {this.state.canSave ? (
                    <div className="save-btn" id="save" onClick={this.saveUser.bind(this)}>
                      <div>{saveEmoji()}</div>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
                <div className="user-details">
                  <div id="basic-info">
                    <form>
                      <div className="full-input">
                        <div className="input-container">
                          <div><label>Phone Number</label></div>
                          <input name="phone" id="drop-phone-input" placeholder={"e.g. 6029199464"} defaultValue={user.phone} onChange={this.prepareInput.bind(this)}></input>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            ) : (
              <span></span>
            )}
            {/* END INFO */}
            {/* DROPS */}
            { this.state.showDrops ? (
              <section className="user-section">
                <div className="section-header">
                  <div className="stick-left">
                    <label>Drops</label>
                  </div>
                  {this.state.canSave ? (
                    <div className="save-btn" id="save" onClick={this.saveUser.bind(this)}>
                      <div>{saveEmoji()}</div>
                    </div>
                  ) : (
                    <span></span>
                  )}
                  {!this.state.viewingDrops ? (
                    <div className="add-btn" onClick={this.viewDropOptions.bind(this)}>Add to Drop</div>
                  ) : (
                    <span></span>
                  )}
                </div>
                { this.state.viewingDrops ? (
                  <div className="user-details">
                    <div id="user-drops" >
                      <Select onChange={this.addUserToDrop.bind(this)}
                        options={dropOptions}
                        defaultValue={selectedDropOption}
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="user-details">
                    { userDrops.length ? (
                      <div id="user-drops" >
                        { userDrops.map((userDrop, i) => {
                          let id = userDrop.uuid;
                          return (
                            <div className="user-drop" key={i}>
                              <div className="user-drop-details" data-id={id} onClick={this.viewUserDrop.bind(this)}>
                                {userDrop.title} - Level {userDrop.level} - {userDrop.achievements_unlocked} unlocked
                              </div>
                            </div>
                          );
                        })
                        }
                      </div>
                    ) : (
                      <div className="no-content">No Drops ☹️</div>
                    )}
                  </div>
                )}
              </section>
            ) : (
              <span></span>
            )}
            {/* END DROPS */}
            {/* USER DROP */}
            { this.state.viewingDrop ? (
              <section id="user-drop-section" className="user-section">
                <div className="section-header">
                  <div className="stick-left">
                    <label>{userDrop.title} Achievements</label>
                    <div className="sub-section-title">Progress: Level {userDrop.level}</div>
                  </div>
                  {this.state.canSaveAchievements ? (
                    <div className="save-btn" id="save" onClick={this.saveUserAchievements.bind(this)}>
                      <div>{saveEmoji()}</div>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
                {/* achievements per level */}
                { userDropAchievements ? (
                  <div id="user-achievements" >
                    { userDropAchievements.map((userAchievement, i) => {
                      let id = userAchievement.uuid;
                      console.log("USER ACHIEVEMENT");
                      console.log(userAchievement);
                      return (
                        <div className="user-achievement" key={i}>
                          <div className="inline-input">
                            <div className="user-achievement-title-container"><label>{userAchievement.achievement.title}</label></div>
                          </div>
                          <div className="inline-input">
                            <div className="user-achievement-status-container"><label><div>Status:</div> <div>{userAchievement.status}</div></label></div>
                          </div>
                          <div className="inline-input">
                            <div className="user-achievement-submission-container">
                              <div><label>Submission</label></div>
                              <input name={`submission`} data-id={id} id={`${id}_submission`}  placeholder={""} defaultValue={userAchievement.submission} onChange={this.prepareAchievementInput.bind(this)}></input>
                            </div>
                          </div>
                          <div className="right-inline-input">
                            <div className="achievement-completion-container">
                              <div><label>Unlocked</label></div>
                              <div className="checkbox-toggle">
                                <input className="checkbox-toggle__input" name={`unlocked`} data-id={id} id={`${id}_unlocked`} type="checkbox" onChange={this.prepareAchievementCheckbox.bind(this)} defaultChecked={userAchievement.unlocked} />
                                <label htmlFor={`${id}_unlocked`} className="checkbox-toggle__label" />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="no-content">No Achievements ☹️</div>
                )}
                <div className="delete-btn" onClick={this.removeUserFromDrop.bind(this)}>Remove from Drop</div>
              </section>
            ) : (
              <span></span>
            )}
            {/* END USER DROP */}
            {/* ORDERS */}
            { this.state.showOrders ? (
              <section className="user-section">
                <div className="section-header">
                  <div className="stick-left">
                    <label>Orders</label>
                  </div>
                </div>
                <div className="user-details">
                  {orders ? (
                    <div id="user-orders">
                    </div>
                  ) : (
                    <div className="no-content">No Orders ☹️</div>
                  )}
                </div>
              </section>
            ) : (
              <span></span>
            )}
            {/* END ORDERS */}
            <div className="spacer" />
            <div className="spacer" />
          </div>
        ) : (
          <div className="loader"></div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const currentUser = fromJS(store.getState().getIn(['app', 'currentUser']));
  const userLoaded = fromJS(store.getState().getIn(['app', 'userLoaded']));
  const user = store.getState().getIn(['app', 'user']);
  const drops = store.getState().getIn(['shop', 'drops']);
  return { currentUser: currentUser, userLoaded: userLoaded, user: user, drops: drops }
}

export default connect(mapStateToProps)(User);
