import {combineReducers} from 'redux-immutable';
import app from './appReducer';
import user from './userReducer';
import shop from './shopReducer';

export default combineReducers({
  app: app,
  user: user,
  shop: shop
});
