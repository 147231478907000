import React, { Component } from 'react';
import store from '../store';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { update, addFaq, deleteFaq, updateFaqs } from '../actions/shopActions';
import { saveEmoji } from '../utils/Config';

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsToUpdate: {},
      faqFieldsToUpdate: {},
      canSave: false,
      canSaveFaqs: false,
      canAddFaq: true,
      maintenanceMode: this.props.maintenanceMode,
      vanityMode: this.props.vanityMode,
      privacy: this.props.privacy,
      terms: this.props.terms,
      showBasicInfo: true,
      showFaqs: false
    };
  }
  prepareInput(event) {
    const { target: { name, value } } = event
    this.setState({
      fieldsToUpdate: Object.assign(
        {},
        this.state.fieldsToUpdate,
        { [name]: value }
      ),
      canSave: true
    });
  }
  prepareCheckbox(event) {
    const { target: { name, checked } } = event
    this.setState({
      fieldsToUpdate: Object.assign(
        {},
        this.state.fieldsToUpdate,
        { [name]: checked }
      ),
      canSave: true
    });
    let check = document.getElementById('shop-open').checked;
  }
  prepareFaqInput(event) {
    const { target: { name, value } } = event;
    const faqId = event.target.dataset.faq_id;
    console.log("preparing FAQ data for save");
    console.log("name");
    console.log(name);
    console.log("val");
    console.log(value);
    console.log("faqId");
    console.log(faqId);
    this.setState({
      faqFieldsToUpdate: Object.assign(
        {},
        this.state.faqFieldsToUpdate,
        { [name]: value }
      ),
      canSaveFaqs: true,
      canAddFaq: false
    });
  }
  save() {
    let data = this.state.fieldsToUpdate
    update(data);
    this.setState({ canSave: false });
  }
  saveFaqs() {
    updateFaqs(this.state.faqFieldsToUpdate);
    this.setState({canSaveFaqs: false, canAddFaq: true});
  }
  removeFaq(event) {
    const id = event.target.dataset.id;
    deleteFaq(id);
  }
  addShopFaq() {
    addFaq();
  }
  openNavItem(section) {
    if (section === "basic") {
      this.setState({ showBasicInfo: true, showFaqs: false});
    } else if (section === "faqs") {
      this.setState({ showBasicInfo: false, showFaqs: true});
    }
  }
  render() {
    let loaded = this.props.loaded;
    let open = this.props.open;
    let maintenanceMode = this.props.maintenanceMode;
    let vanityMode = this.props.vanityMode;
    let terms = this.props.terms;
    let privacy = this.props.privacy;
    let rules = this.props.rules;
    let faqs = this.props.faqs;
    return (
      <div name="Settings" id="settings">
        <div id="hero-logo-container">
          <Link to="/control-tower">
            <img id="hero-logo" alt="Hero Logo" src="/h-mark.svg" />
          </Link>
        </div>
        { (loaded) ? (
          <div id="page-nav">
            {this.state.showBasicInfo ? (
              <div onClick={this.openNavItem.bind(this, "basic")} className="active-nav-btn">Basic Info</div>
            ) : (
              <div onClick={this.openNavItem.bind(this, "basic")} className="nav-btn">Basic Info</div>
            )}
            {this.state.showFaqs ? (
              <div onClick={this.openNavItem.bind(this, "faqs")} className="active-nav-btn">FAQs</div>
            ) : (
              <div onClick={this.openNavItem.bind(this, "faqs")} className="nav-btn">FAQs</div>
            )}
          </div>
        ) : (
          <span></span>
        )}
        {this.state.showBasicInfo ? (
          <div id="settings-content">
          {(loaded) ? (
            <div id="settings-container">
              <section className="settings-section">
                <div className="section-header">
                  <label id="settings-label">Settings</label>
                  {this.state.canSave ? (
                    <div className="save-btn" id="save" onClick={this.save.bind(this)}>
                      <div>{saveEmoji()}</div>
                    </div>
                  ) : (
                    <span></span>
                  )}
                </div>
                <div className="settings-details">
                  <div className="settings-options">
                    <form>
                      <div className="split-input">
                        <div className="split-left">
                          <div className="input-container">
                            <div><label>Shop Open</label></div>
                            <div className="checkbox-toggle">
                              <input className="checkbox-toggle__input" name={"open"} id={"shop-open"} type="checkbox" data-id={"shop-open"} onChange={this.prepareCheckbox.bind(this)} defaultChecked={open} />
                              <label htmlFor={"shop-open"} className="checkbox-toggle__label" />
                            </div>
                          </div>
                          <div className="input-container">
                            <div><label>Vanity Mode</label></div>
                            <div className="checkbox-toggle">
                              <input className="checkbox-toggle__input" name={"vanity_mode"} id={"vanity-mode"} type="checkbox" data-id={"vanity-mode"} onChange={this.prepareCheckbox.bind(this)} defaultChecked={vanityMode} />
                              <label htmlFor={"vanity-mode"} className="checkbox-toggle__label" />
                            </div>
                          </div>
                          <div className="input-container">
                            <div><label>Maintenance Mode</label></div>
                            <div className="checkbox-toggle">
                              <input className="checkbox-toggle__input" name={"maintenance_mode"} id={"maintenance-mode"} type="checkbox" data-id={"maintenance-mode"} onChange={this.prepareCheckbox.bind(this)} defaultChecked={maintenanceMode} />
                              <label htmlFor={"maintenance-mode"} className="checkbox-toggle__label" />
                            </div>
                          </div>
                          <div className="input-container">
                            <div><label>Privacy</label></div>
                            <textarea name="privacy" id="privacy-input" defaultValue={privacy} onChange={this.prepareInput.bind(this)}></textarea>
                          </div>
                          <div className="input-container">
                            <div><label>Terms</label></div>
                            <textarea name="terms" id="terms-input" defaultValue={terms} onChange={this.prepareInput.bind(this)}></textarea>
                          </div>
                            <div className="input-container">
                              <div><label>Rules</label></div>
                              <textarea name="rules" id="rules-input" defaultValue={rules} onChange={this.prepareInput.bind(this)}></textarea>
                            </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </section>
            </div>
          ) : (
            <div className="loader"/>
          )}
        </div>
        ) : (
          <div id="settings-content">
            <section id="settings-container">
              <div className="faqs-header">
                <div className="stick-left">
                  <label>FAQs</label>
                </div>
                {this.state.canSaveFaqs ? (
                  <div className="save-btn" id="save-faqs" onClick={this.saveFaqs.bind(this)}>
                    <div>{saveEmoji()}</div>
                  </div>
                ) : (
                  <span></span>
                )}
                {this.state.canAddFaq ? (
                  <div id="add-faq-btn" onClick={this.addShopFaq.bind(this)}>Add FAQ</div>
                ) : (
                  <span></span>
                )}
              </div>
              {faqs ? (
                <div id="faqs-container">
                  { faqs.map((faq, i) => {
                    let position = faq.position;
                    console.log("faq");
                    console.log(faq);
                    let numberFormatted = `#${position}`;
                    let questionFieldName = `faq_${position}_question`;
                    let answerFieldName = `faq_${position}_answer`;
                    return (
                    <div className="faq-section">
                      <div className="drop-details">
                      <label className="faq-label">{numberFormatted}</label> 
                      <div id="basic-info">
                        <form>
                          {/* FAQ */}
                          <div className="qa-container">
                            <div className="full-input">
                              <div className="input-container">
                                <div><label>Question</label></div>
                                <input name={questionFieldName} id={questionFieldName} data-faq_id={faq.uuid} defaultValue={faq.question} onChange={this.prepareFaqInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Answer</label></div>
                                  <textarea name={answerFieldName} id={answerFieldName} data-faq_id={faq.uuid} defaultValue={faq.answer} onChange={this.prepareFaqInput.bind(this)}></textarea>
                              </div>
                            </div>
                            <img alt="trash faq" data-id={faq.uuid} className="trash-faq" src="/bin.png" onClick={this.removeFaq.bind(this)} />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  );
                  })}
                </div>
              ) : (
                <span>
                </span>
              )}
            </section>
          </div>
        )}
        <div className="spacer" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const open = fromJS(store.getState().getIn(['shop', 'open']));
  const maintenanceMode = fromJS(store.getState().getIn(['shop', 'maintenanceMode']));
  const vanityMode = fromJS(store.getState().getIn(['shop', 'vanityMode']));
  const shopLoaded = fromJS(store.getState().getIn(['shop', 'shopLoaded']));
  const terms = fromJS(store.getState().getIn(['shop', 'terms']));
  const privacy = fromJS(store.getState().getIn(['shop', 'privacy']));
  const rules = fromJS(store.getState().getIn(['shop', 'rules']));
  const faqs = fromJS(store.getState().getIn(['shop', 'faqs'])).toJS();
  return { faqs: faqs, open: open, maintenanceMode: maintenanceMode, loaded: shopLoaded, vanityMode: vanityMode, terms: terms, privacy: privacy, rules: rules }
}

export default connect(mapStateToProps)(Settings);
