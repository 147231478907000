import Immutable from 'immutable';

const initialState = {
  userId: null,
  token: null,
  email: null,
  waitingListPosition: null
};

export default function reducer(state=Immutable.fromJS(initialState), action) {
  switch(action.type){
    default:
      return state;
  }
}
