import Immutable from 'immutable';

const initialState = {
  containerWidth: null,
  show: false,
  usersLoaded: false,
  userLoaded: false,
  users: [],
  user: null,
  loggedIn: false
};

export default function reducer(state=Immutable.fromJS(initialState), action) {
  switch(action.type){
    case 'SET_USERS':
      return state.set('users', action.users).set('usersLoaded', true);
    case 'SET_USER':
      return state.set('user', action.user).set('userLoaded', true);
    case 'LOGIN':
      return state.set('loggedIn', true);
    case 'LOGOUT':
      return state.set('loggedIn', false);
    default:
      return state;
  }
}
