import React, { Component } from 'react';
import store from '../store';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { createDrop } from '../actions/dropActions';
import { saveEmoji } from '../utils/Config';

class NewDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
      productId: null,
      canSave: false
    };
  } 
  componentDidUpdate() {
    if (this.state.productId && this.state.title && this.state.canSave === false) {
      this.setState({ canSave: true });
    }
  }
  setProduct(product) {
    this.setState({ productId: product.value});
  }
  prepareInput(event) {
    const { target: { value } } = event
    this.setState({title: value});
  }
  save() {
    let data = {product_uuid: this.state.productId, title: this.state.title}
    createDrop(data);
  }
  render() {
    let loaded = this.props.productsLoaded;
    let products = this.props.products;
    let options = [];
    products.map((product, i) => {
      let title = product.title;
      let id = product.uuid;
      options.push({ value: id, label: title });
    });
    let headerStyle = {
      paddingTop: "20px"
    }
    return (
      <div name="Drop" id="drop">
        <div id="hero-logo-container">
          <Link to="/control-tower">
            <img id="hero-logo" alt="Hero Logo" src="/h-mark.svg" />
          </Link>
        </div>
        {loaded ? (
          <div id="drop-container">
            { products ? (
              <div id="drop-info">
                {/* DROP INFO */}
                  <section className="drop-section">
                    <div className="level-header" style={headerStyle}>
                      <div className="stick-left">
                        <label>New Drop</label>
                      </div>
                      {this.state.canSave ? (
                        <div className="save-btn" id="save" onClick={this.save.bind(this)}>
                          <div>{saveEmoji()}</div>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <div className="drop-details">
                      <div id="basic-info">
                        <form>
                          <div className="full-input">
                            <div className="select-container">
                              <div className="input-container">
                                <div><label>Product</label></div>
                              </div>
                            <Select onChange={this.setProduct.bind(this)} options={options} menuPortalTarget={document.body}
                              menuPosition={'fixed'}  />
                            </div>
                            <div className="input-container">
                              <div><label>Title</label></div>
                              <input name="title" id="drop-title-input" defaultValue={""} onChange={this.prepareInput.bind(this)}></input>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </section>
                {/* END DROP INFO */}
              </div>
            ) : (
              <div id="products-missing">
                Ooops ☹️
              </div>
            )}
          </div>
        ) : (
          <div className="loader"></div>
        )}
        <div className="spacer" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const products = fromJS(store.getState().getIn(['shop', 'products'])).toJS();
  const productsLoaded = fromJS(store.getState().getIn(['shop', 'productsLoaded']));
  return { products: products, productsLoaded: productsLoaded }
}

export default connect(mapStateToProps)(NewDrop);
