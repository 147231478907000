import React, { Component } from 'react';
import store from '../store';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { downloadRequests } from '../actions/shopActions';

class RetailRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  downloadRequestsFile() {
    window.open('https://staging-api.hero.co/api/v1/admin/download-requests/?');
  }
  render() {
    let loaded = this.props.loaded;
    return (
      <div name="RetailRequests" id="retail-requests">
        <div id="hero-logo-container">
          <Link to="/control-tower">
            <img id="hero-logo" alt="Hero Logo" src="/h-mark.svg" />
          </Link>
        </div>
        <div id="settings-content">
          {(loaded) ? (
            <div id="settings-container">
              <section className="settings-section">
                <div className="section-header">
                  <label id="settings-label">Retail Requests</label>
                </div>
                <div id="download-requests-file-btn" onClick={this.downloadRequestsFile.bind(this)}>
                  Download
                </div>
              </section>
            </div>
          ) : (
            <div className="loader" />
          )}
        </div>
        <div className="spacer" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const shopLoaded = fromJS(store.getState().getIn(['shop', 'shopLoaded']));
  return { loaded: shopLoaded }
}

export default connect(mapStateToProps)(RetailRequests);
