import Immutable from 'immutable';

const initialState = {
  products: [],
  productsInCart: [],
  drops: [],
  currentProduct: null,
  currentDrop: null,
  currentAchievement: null,
  productsLoaded: false,
  dropsLoaded: false,
  shopLoaded: false,
  userCount: 0,
  orderCount: 0,
  open: false,
  maintenanceMode: false,
  vanityMode: false,
  privacy: null,
  terms: null,
  faqs: [],
  pressReleases: [],
  mediaKit: null,
  elections: [],
  locations: []
};

export default function reducer(state = Immutable.fromJS(initialState), action) {
  switch (action.type) {
    case 'SETUP':
      let storeData = action.data;
      let accessCode = storeData.code;
      let shop = storeData.shop;
      let hmac = storeData.hmac;
      return { ...state, shopUrl: shop, hmac: hmac, accessCode: accessCode };
    case 'SET_PRODUCTS':
      return state.set('products', action.products).set('productsLoaded', true);
    case 'SET_DROPS':
      return state.set('drops', action.drops).set('dropsLoaded', true);
    case 'LOADING_PRODUCTS':
      return state.set('productsLoaded', false);
    case 'LOADING_DROPS':
      return state.set('dropsLoaded', false);
    case 'SET_CURRENT_PRODUCT':
        return state.set('currentProduct', action.id);
    case 'SET_CURRENT_DROP':
      return state.set('currentDrop', action.id);
    case 'SET_CURRENT_ACHIEVEMENT':
      return state.set('currentAchievement', action.id);
    case 'SET_STATS':
      return state.set('userCount', action.userCount).set('orderCount', action.orderCount);
    case 'SET_SHOP_INFO':
      return state.set('open', action.shop.open).set('maintenanceMode', action.shop.maintenance_mode).set('vanityMode', action.shop.vanity_mode).set('faqs', action.shop.faqs).set('pressReleases', action.shop.press_releases).set('mediaKit', action.shop.media_kit).set('terms', action.shop.terms).set('rules', action.shop.rules).set('privacy', action.shop.privacy).set('elections', action.elections).set('locations', JSON.parse(action.locations)).set('shopLoaded', true);
    default:
      return state;
  }
}
