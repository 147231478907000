import Cookies from 'js-cookie';
export function baseURL() {
  // return 'http://api.lvh.me:3000';
  return 'https://staging-api.hero.co';
}
export function appVersion() {
  return '1.0.0';
}

export function token() {
  const authToken = Cookies.get('authToken');
  if (authToken) {
    return authToken;
  } else {
    return "";
  }
}


export function saveEmoji() {
  return '👍';
}

export function isLoggedIn() {
  const loggedIn = Cookies.get('authToken');
  return loggedIn;
}

export function headers() {
  const options = {
    baseURL: baseURL(),
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + token(),
      'x-app-version': appVersion(),
      'Access-Control-Allow-Origin': baseURL()
    }
  };
  return options;
}

export function formHeaders() {
  const options = {
    baseURL: baseURL(),
    headers: {
      'Content-Type': 'multipart/form-data',
      'authorization': 'Bearer ' + token(),
      'x-app-version': appVersion(),
      'Access-Control-Allow-Origin': baseURL()
    }
  };
  return options;
}

export function getQueryParam(param) {
  var rx = new RegExp("[?&]" + param + "=([^&]+).*$");
  var returnVal = window.location.search.match(rx);
  return returnVal === null ? "" : decodeURIComponent(returnVal[1].replace(/\+/g, " "));
} //let shopifyHmac = getQueryParam('hmac');


export function checkAuth(error) {
  if (error.response) {
    if (error.response.status === 401) {
      Cookies.set('loggedIn', false);
      Cookies.set('authToken', "");
    }
  }
}
