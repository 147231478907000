import React, { Component } from 'react';
import store from '../store';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { checkoutPreorder } from '../actions/storefrontActions';
import { Link } from "react-router-dom";
import { setCurrentDrop } from '../actions/dropActions';
import { baseURL } from '../utils/Config';

class drops extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  componentWillMount() {
    setCurrentDrop(null);
  }
  render() {
    const drops = this.props.drops;
    const loaded = this.props.dropsLoaded;
    return (
      <div name="drops" id="drops">
        <div id="hero-logo-container">
          <Link to="/control-tower">
            <img id="hero-logo" alt="Hero Logo" src="/h-mark.svg" />
          </Link>
        </div>
        { loaded ? (
          <Link to="/new-drop">
            <div id="sync-drops-btn"><div>Create Drop</div></div>
          </Link>
        ) : (
          <span></span>
        )}
        { loaded ? (
          <div name="hero-drops" id="drops-container">
            { drops.map((drop, i) => {
              console.log("drop");
              console.log(drop);
              let title = drop.title;
              let picUrl = null;
              let uuid = drop.uuid;
              let product = null;
              let base = baseURL();
              let active = drop.active;
              if (drop.product) {
                product = drop.product;
                let variants = product.variants;
                let product_images = product.images;
                if (product_images.length) {
                  picUrl = `${base}${product_images[0].url}`;
                } else {
                  if (variants.length) {
                    picUrl = product.variants[0].shopify_image_url;
                  } else {
                    picUrl = "h-mark.svg"
                  }
                }
              }
              return (
                <Link to={"/drops/" + uuid} key={i}>
                  <div className="hero-drop-container">
                    { active ? (
                      <img alt="visible" className="visible" src="/check.png" />
                    ) : (
                      <span />
                    )}
                    <img alt="hero drop" src={picUrl} />
                    <div className="drop-details">
                      <div className="drop-title">{title}</div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        ) : (
          <div className="loader"></div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const drops = fromJS(store.getState().getIn(['shop', 'drops'])).toJS();
  const dropsLoaded = fromJS(store.getState().getIn(['shop', 'dropsLoaded']));
  return { drops: drops, dropsLoaded: dropsLoaded }
}

export default connect(mapStateToProps)(drops);
