// )\    /(  )\.---.          /(,-.   )\.---.            .'(     /`-.      /`-.   )\  )\   )\.---.     )\.-.  
// \ (_.' / (   ,-._(       ,' _   ) (   ,-._(       ,') \  )  ,' _  \   ,' _  \ (  \, /  (   ,-._(  ,'     ) 
//  )  _.'   \  '-,        (  '-' (   \  '-,        (  /(/ /  (  '-' (  (  '-' (  ) \ (    \  '-,   (  .-, (  
//  / /       ) ,-`         )  _   )   ) ,-`         )    (    )   _  )  ) ,_ .' ( ( \ \    ) ,-`    ) '._\ ) 
// (  \      (  ``-.       (  '-' /   (  ``-.       (  .'\ \  (  ,' ) \ (  ' ) \  `.)/  )  (  ``-.  (  ,   (  
//  ).'       )..-.(        )/._.'     )..-.(        )/   )/   )/    )/  )/   )/     '.(    )..-.(   )/ ._.'  
//     _,_
//   /7/Y/^\
//   vuVV|C)|                        __ _
//     \|^ /                       .'  Y '>,
//     )| \)                      / _   _   \
//    //)|\\                      )(_) (_)(|}
//   / ^| \ \                     {  4A   } /
//  //^| || \\                     \uLuJJ/\l
// >//||| |\\\|                    |3    p)/
// | """""  7/>l__ _____ ____      /nnm_n//
// L>_   _-< 7/|_-__,__-)\,__)(".  \_>-<_/D
// )D" Y "c)  9)       //V     \_"-._.__G G_c__.-__<"/
//  | | |  |(|               < "-._"> _.G_.___)\   \7\
//   \"=" // |              (,"-.__.|\ \<.__.-" )   \ \
//    '---'  |              |,"-.__"| \!"-.__.-".\   \ \
//      |_;._/              (_"-.__"'\ \"-.__.-".|    \_\
//      )(" V                \"-.__"'|\ \-.__.-".)     \ \
//         (                  "-.__'"\_\ \.__.-"./      \ l
//          )                  ".__"">>G\ \__.-">        V )
//                                 ""  G<\ \_.-"        ./7
//                                      G<\ \          ///
//                                 ___  GD'
//                            /  /             )E_>"
//                          _/  (             |  \()
//                         / \ /              |  |
//                         /\\|               |  |
//                        / '((               |  |
//                       /  / )               \  |
//                      /  y                  |Y |
//                     /  /                   |  |
//                    L ."                    |  /
//                   | \(                     |  |
//                    \_|                     |  |
//                    |  \                    { "|
//                    | ||                    |  |
//                    |x||                    \_,/
//                    } ||                    / \'
//                    | ||                    |_/
//                    | (|                    | }\
//                    | ||                    } ||
//                    | ||                    | ||
//                    | ||                    |\||
//                    / ||                    | ||
//                    | ||                    ( |!
//                    | |/                    ) ||
//                  _/   \                    | }|
//              _.-"_ ( )|                    ! ||
//           c_"-_-"_    )                    | ||
//            c,-_-'_--""                     { ||
//            "C(_/"                          \ /|
//                                            (! )
//                                            /| \
//                                           /  |(
//                                          /7||\\
//                                         ()U cUu"
// *** REFACTOR-FEST 2021
// *** nothing set in stone, so hoping we come out of this CMS project with some common components to use in each section and this becomes < 500 lines of code 
//

import React, { Component } from 'react';
import store from '../store';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { setCurrentDrop, update, updateStartDate, updateEndDate, updateDeliveryDate, addBadge, removeBadge, addLevel, updateLevel, deleteLevel, addAchievement,
  saveAchievement, addAchievementImage, addRewardImage, addInfoImage, removeAchievementImage, removeRewardImage, removeInfoImage, deleteAchievement, addLevelRewardImage, removeLevelRewardImage,
  addImage, removeImage, addTag, removeTag, addFaq, updateFaqs, deleteFaq, savePost, selectWinners, reselectWinners, deleteUserEntry, deletePotentialWinner, swapPotentialWinner, confirmWinners, archiveEntries } from '../actions/dropActions';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { baseURL, saveEmoji } from '../utils/Config';


class Drop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.currentDrop,
      productId: null,
      achievementId: this.props.currentAchievement,
      levelId: null,
      levelNumber: null,
      deliveryDate: null,
      startDate: null,
      endDate: null,
      canSave: false,
      fieldsToUpdate: {},
      achievementFieldsToUpdate: {},
      levelFieldsToUpdate: {},
      faqFieldsToUpdate: {},
      canSave: false,
      canSaveAchievement: false,
      canSaveLevel: false,
      canSaveFaqs: false,
      canSaveSweepstakes: false,
      canSaveDrawWinners: false,
      numberOfWinnersToDraw: null,
      canAddFaq: true,
      showBasicInfo: true,
      showAssets: false,
      showAchievements: false,
      showNewAchievement: false,
      showEditAchievement: false,
      showLevelSettings: false,
      showSweepstakes: false,
      showEntries: false,
      showPotentialWinners: false,
      showWinners: false,
      showDrawWinners: false,
      showFAQs: false,
      showFAQ1: false,
      showFAQ2: false,
      showFAQ3: false,
      showFAQ4: false,
      showFAQ5: false,
      showFAQ6: false,
      showFAQ7: false,
      showFAQ8: false,
      showConfirmDeleteLevel: false,
      showConfirmDeleteAchievement: false,
      showAddSocialPost: false,
      newSocialPostUrl: "",
      newSocialPostImage: null,
      newSocialPostPlatform: ""
    };
  }
  componentWillMount() {
    const id = this.props.match.params.id;
    this.setState({ id: id });
    setCurrentDrop(id);
  }
  componentDidUpdate() {
    if (this.state.productId && this.state.title && this.state.canSave === false) {
      this.setState({ canSave: true });
    }
    if (this.props.currentAchievement && this.state.showEditAchievement === false) {
      this.setState({showEditAchievement: true, showNewAchievement: false});
    }
  }
  setProduct(product) {
    this.setState({
      fieldsToUpdate: Object.assign(
        {},
        this.state.fieldsToUpdate,
        { ["product_uuid"]: product.value }
      ),
      canSave: true,
      productId: product.value
    });
  }
  prepareInput(event) {
    const { target: { value } } = event
    this.setState({ title: value });
  }
  save() {
    const fieldsToUpdate = this.state.fieldsToUpdate;
    const dropId = this.state.id;
    update(dropId, fieldsToUpdate);
    this.setState({canSave: false});
  }
  saveLevel() {
    const fieldsToUpdate = this.state.levelFieldsToUpdate;
    const levelId = this.state.levelId;
    updateLevel(levelId, fieldsToUpdate);
    this.setState({ canSaveLevel: false });
  }
  prepareCheckbox(event) {
    const { target: { name, checked } } = event
    this.setState({
      fieldsToUpdate: Object.assign(
        {},
        this.state.fieldsToUpdate,
        { [name]: checked }
      ),
      canSave: true
    });
  }
  prepareInput(event) {
    const { target: { name, value } } = event
    this.setState({
      fieldsToUpdate: Object.assign(
        {},
        this.state.fieldsToUpdate,
        { [name]: value }
      ),
      canSave: true
    });
  }
  prepareAchievementInput(event) {
    const {target: { name, value } } = event;
    this.setState({
      achievementFieldsToUpdate: Object.assign(
        {},
        this.state.achievementFieldsToUpdate,
        { [name]: value}
      ),
      canSaveAchievement: true
    });
  }
  archiveUserEntries() {
    archiveEntries(this.state.id);
  }
  prepareFaqInput(event) {
    const { target: { name, value } } = event;
    const faqId = event.target.dataset.faq_id;
    console.log("preparing FAQ data for save");
    console.log("name");
    console.log(name);
    console.log("val");
    console.log(value);
    console.log("faqId");
    console.log(faqId);
    this.setState({
      faqFieldsToUpdate: Object.assign(
        {},
        this.state.faqFieldsToUpdate,
        { [name]: value }
      ),
      canSaveFaqs: true,
      canAddFaq: false
    });
  }
  prepareAchievementTag(event) {
    const { target: { value } } = event;
    if (event.key === 'Enter') {
      const achievementId = event.target.dataset.achievement_id;
      let tag = value;
      addTag(achievementId, tag);
    }
  }
  prepareLevelInput(event) {
    const { target: { name, value } } = event;
    this.setState({
      levelFieldsToUpdate: Object.assign(
        {},
        this.state.levelFieldsToUpdate,
        { [name]: value }
      ),
      canSaveLevel: true
    });
  }
  prepareLevelCheckbox(event) {
    const { target: { name, checked } } = event;
    this.setState({
      levelFieldsToUpdate: Object.assign(
        {},
        this.state.levelFieldsToUpdate,
        { [name]: checked }
      ),
      canSaveLevel: true
    });
  }
  prepareAchievementCheckbox(event) {
    const { target: { name, checked } } = event
    this.setState({
      achievementFieldsToUpdate: Object.assign(
        {},
        this.state.achievementFieldsToUpdate,
        { [name]: checked }
      ),
      canSaveAchievement: true
    });
  }
  prepareDrawNumberInput(event) {
    const { target: { value } } = event
    this.setState({ numberOfWinnersToDraw: value, canSaveDrawWinners: true });
  }
  setAchievementType(achievementType) {
    this.setState({
      achievementFieldsToUpdate: Object.assign(
        {},
        this.state.achievementFieldsToUpdate,
        { ["achievement_type"]: achievementType.value }
      ),
      canSaveAchievement: true
    });
  }
  setAchievementProduct(product) {
    this.setState({
      achievementFieldsToUpdate: Object.assign(
        {},
        this.state.achievementFieldsToUpdate,
        { ["product_uuid"]: product.value }
      ),
      canSaveAchievement: true
    });
  }
  setLevelProduct(product) {
    this.setState({
      levelFieldsToUpdate: Object.assign(
        {},
        this.state.levelFieldsToUpdate,
        { ["product_uuid"]: product.value }
      ),
      canSaveLevel: true
    });
  }
  openNavItem(section) {
    if (section === "basic") {
      store.dispatch({ type: "SET_CURRENT_ACHIEVEMENT", id: null });
      this.setState({ showBasicInfo: true, showAssets: false, showAchievements: false, showNewAchievement: false, showEditAchievement: false, showLevelSettings: false, showConfirmDeleteLevel: false, showFAQs: false, canSave: false, canSaveAchievement: false, showSweepstakes: false, showEntries: false,  levelId: null, levelNumber: null, achievementFieldsToUpdate: {} });
    } else if (section === "assets") {
      store.dispatch({ type: "SET_CURRENT_ACHIEVEMENT", id: null });
      this.setState({ showBasicInfo: false, showAssets: true, showAchievements: false, showNewAchievement: false, showEditAchievement: false, showLevelSettings: false, showConfirmDeleteLevel: false, showFAQs: false, canSave: false, canSaveAchievement: false, showSweepstakes: false, showEntries: false,  levelId: null, levelNumber: null, achievementFieldsToUpdate: {} });
    } else if (section === "achievements") {
      store.dispatch({type: "SET_CURRENT_ACHIEVEMENT", id: null});
      this.setState({ showBasicInfo: false, showAssets: false, showAchievements: true, showNewAchievement: false, showEditAchievement: false, showLevelSettings: false, showConfirmDeleteLevel: false, showFAQs: false, canSave: false, canSaveAchievement: false, showSweepstakes: false, showEntries: false,  levelId: null, levelNumber: null, achievementFieldsToUpdate: {} });
    } else if (section === "faqs") {
      store.dispatch({ type: "SET_CURRENT_ACHIEVEMENT", id: null });
      this.setState({ showBasicInfo: false, showAssets: false, showAchievements: false, showNewAchievement: false, showEditAchievement: false, showLevelSettings: false, showConfirmDeleteLevel: false, showFAQs: true, canSave: false, canSaveAchievement: false, showSweepstakes: false, showEntries: false, levelId: null, levelNumber: null, achievementFieldsToUpdate: {} });
    } else if (section === "sweepstakes") {
      store.dispatch({ type: "SET_CURRENT_ACHIEVEMENT", id: null });
      this.setState({ showBasicInfo: false, showAssets: false, showAchievements: false, showNewAchievement: false, showEditAchievement: false, showLevelSettings: false, showConfirmDeleteLevel: false, showFAQs: false, canSave: false, canSaveAchievement: false, showSweepstakes: true, showEntries: true, showPotentialWinners: false, showWinners: false, levelId: null, levelNumber: null, achievementFieldsToUpdate: {} });
    }
  }
  openSwitchItem(switchItem) {
    if (switchItem === "entries") {
      this.setState({ showEntries: true, showPotentialWinners: false, showWinners: false });
    } else if (switchItem === "potential-winners") {
      this.setState({ showEntries: false, showPotentialWinners: true, showWinners: false });
    } else if (switchItem === "winners") {
      this.setState({ showEntries: false, showPotentialWinners: false, showWinners: true });
    }
  }
  openCloseFAQ(event) {
    let faqNumber = event.target.dataset.faq;
    let key = `showFAQ${faqNumber}`;
    let faqState = this.state[key];
    this.setState({ [key]: !faqState });
  }
  handleStartDateChange(date) {
    let startDate = moment.utc(date).format();
    this.setState({
      startDate: date
    });
    updateStartDate(this.state.id, startDate);
  }
  handleEndDateChange(date) {
    let endDate = moment.utc(date).format();
    this.setState({
      endDate: date
    });
    updateEndDate(this.state.id, endDate);
  }
  handleDeliveryDateChange(date) {
    let deliveryDate = moment.utc(date).format();
    this.setState({
      deliveryDate: date
    });
    updateDeliveryDate(this.state.id, deliveryDate);
  }
  prepareBadgeImage(event) {
    const files = event.target.files;
    const id = event.target.dataset.id;
    addBadge(id, files[0]);
  }
  prepareDropImage(event) {
    const files = event.target.files;
    const id = event.target.dataset.id;
    addImage(id, files[0]);
  }
  deleteBadgeImage(event) {
    const dropId = event.target.dataset.drop_id;
    removeBadge(dropId);
  }
  deleteDropImage(event) {
    const dropId = event.target.dataset.drop_id;
    removeImage(dropId);
  }
  deleteTag(event) {
    const achievementId = event.target.dataset.achievement_id;
    const tag = event.target.dataset.tag;
    removeTag(achievementId, tag);
  }
  addDropLevel() {
    let dropId = this.state.id;
    addLevel(dropId);
  }
  addDropFaq() {
    let dropId = this.state.id;
    addFaq(dropId);
  }
  newAchievement(id, number) {
    let levelId = id;
    let levelNumber = number;
    this.setState({ levelId: levelId, levelNumber: levelNumber, showBasicInfo: false, showAssets: false, showAchievements: false, showNewAchievement: true, showLevelSettings: false, showFAQs: false, canSave: false });
  }
  editLevel(id, number, drop) {
    let levelId = id;
    let levelNumber = number;
    this.setState({ levelId: levelId, levelNumber: levelNumber, showBasicInfo: false, showAssets: false, showAchievements: false, showNewAchievement: false, showLevelSettings: true, showFAQs: false, canSave: false });
  }
  deleteDropLevel(id) {
    deleteLevel(id);
    this.setState({ showLevelSettings: false, showConfirmDeleteLevel: false, showAchievements: true, levelId: null, levelNumber: null });
  }
  deleteDropAchievement(id) {
    deleteAchievement(id);
    store.dispatch({ type: "SET_CURRENT_ACHIEVEMENT", id: null });
    this.setState({ showEditAchievement: false, showNewAchievement: false, showConfirmDeleteAchievement: false, showAchievements: true, achievementId: null, currentAchievement: null, levelNumber: null, levelId: null });
  }
  confirmDeleteDropLevel(){
    this.setState({ showConfirmDeleteLevel: true });
  }
  confirmDeleteAchievement(){
    this.setState({ showConfirmDeleteAchievement: true});
  }
  addDropAchievement(event) {
    let dropId = this.state.id;
    let levelId = this.state.levelId;
    const fieldsToUpdate = this.state.achievementFieldsToUpdate;
    this.setState({ canSaveAchievement: false });
    addAchievement(dropId, levelId, fieldsToUpdate);
  }
  editDropAchievement(levelId, levelNumber, achievementId) {
    this.setState({ levelId: levelId, levelNumber: levelNumber, showBasicInfo: false, showAssets: false, showAchievements: false, showLevelSettings: false, showNewAchievement: false, showEditAchievement: true, showFAQs: false, canSave: false });
    store.dispatch({type: "SET_CURRENT_ACHIEVEMENT", id: achievementId});
  }
  saveDropAchievement(event) {
    let dropId = this.state.id;
    let achievementId = this.props.currentAchievement;
    const fieldsToUpdate = this.state.achievementFieldsToUpdate;
    this.setState({ canSaveAchievement: false });
    saveAchievement(dropId, achievementId, fieldsToUpdate);
  }
  prepareAchievementImage(event) {
    const files = event.target.files;
    const id = event.target.dataset.id;
    addAchievementImage(id, files[0]);
  }
  prepareInfoImage(event) {
    const files = event.target.files;
    const id = event.target.dataset.id;
    addInfoImage(id, files[0]);
  }
  deleteAchievementImage(event) {
    const achievementId = event.target.dataset.achievement_id;
    removeAchievementImage(achievementId);
  }
  deleteInfoImage(event) {
    const achievementId = event.target.dataset.achievement_id;
    removeInfoImage(achievementId);
  }
  deleteRewardImage(event) {
    const dropId = event.target.dataset.drop_id;
    removeRewardImage(dropId);
  }
  deleteLevelRewardImage(event) {
    const dropLevelId = event.target.dataset.drop_level_id;
    removeLevelRewardImage(dropLevelId);
  }
  prepareRewardImage(event) {
    const files = event.target.files;
    const id = event.target.dataset.id;
    addRewardImage(id, files[0]);
  }
  prepareLevelRewardImage(event) {
    const files = event.target.files;
    const id = event.target.dataset.id;
    addLevelRewardImage(id, files[0]);
  }
  saveFaqs() {
    console.log(this.state.faqFieldsToUpdate);
    updateFaqs(this.state.id, this.state.faqFieldsToUpdate);
    this.setState({canSaveFaqs: false, canAddFaq: true});
  }
  removeFaq(event) {
    const id = event.target.dataset.id;
    deleteFaq(this.state.id, id);
  }
  saveDrawWinners() {
    selectWinners(this.state.id, this.state.numberOfWinnersToDraw);
    this.setState({canSaveDrawWinners: false, showDrawWinners: false, showEntries: false, showPotentialWinners: true});
  }
  reselectDrawWinners() {
    reselectWinners(this.state.id);
    this.setState({canSaveDrawWinners: false, showDrawWinners: false, showEntries: false, showPotentialWinners: true});
  }
  removeEntry(event) {
    const id = event.target.dataset.id;
    deleteUserEntry(id);
  }
  removePotentialWinner(event) {
    const id = event.target.dataset.id;
    deletePotentialWinner(id);
  }
  changePotentialWinner(event) {
    const id = event.target.dataset.id;
    swapPotentialWinner(id);
  }
  saveSweepstakes() {
    alert("YOOOOO");
  }
  showAddSocialPost() {
    this.setState({ showAddSocialPost: true });
  }
  prepareSocialPostImage(event) {
    const file = event.target.files[0];
    this.setState({newSocialPostImage: file});
  }
  setSocialPostUrl(event) {
    const { target: { value } } = event
    this.setState({ newSocialPostUrl: value });
  }
  setSocialPostPlatform(platform) {
    this.setState({ newSocialPostPlatform: platform.value });
  }
  saveSocialPost() {
    savePost(this.state.id, this.state.newSocialPostUrl, this.state.newSocialPostPlatform, this.state.newSocialPostImage);
    this.setState({ newSocialPostPlatform: "", newSocialPostUrl: "", newSocialPostImage: null, showAddSocialPost: false });
  }
  showDrawWinners() {
    this.setState({showDrawWinners: true});
  }
  convertWinners() {
    confirmWinners(this.state.id);
  }
  render() {
    let dropsLoaded = this.props.dropsLoaded;
    let productsLoaded = this.props.productsLoaded;
    let loaded = (dropsLoaded && productsLoaded);
    let achievement;
    const id = this.state.id;
    let productId;
    let product;
    let drop;
    let startDate;
    let endDate;
    let deliveryDate;
    let base = baseURL();
    let drops = this.props.drops;
    let badgeImageStyle;
    let badgeUrl;
    let dropImageStyle;
    let dropImageUrl;
    let achievementImageStyle;
    let achievementImageUrl;
    let rewardImageStyle;
    let rewardUrl;
    let dropReward;
    let dropRewardImageStyle;
    let dropRewardImageUrl;
    let levelRewardImageStyle;
    let levelRewardImageUrl;
    let infoImageStyle;
    let infoImageUrl;
    let dropLevels;
    let dropLevel;
    let selectedLevelProductOption;
    let products = this.props.products;
    let options = [];
    let socialPosts = [];
    let faqs;
    let sweepstakesWinners;
    let potentialSweepstakesWinners;
    let userEntries;
    let sweepstakesWinnersCount;
    let potentialSweepstakesWinnersCount;
    let userEntriesCount;
    let canSaveSocialPost = false;
    if (this.state.newSocialPostImage && this.state.newSocialPostPlatform.length && this.state.newSocialPostUrl.length) {
      canSaveSocialPost = true;
    }
    console.log(canSaveSocialPost);
    let achievementTags;
    products.map((product, i) => {
      let title = product.title;
      let optionId = product.uuid;
      options.push({ value: optionId, label: title });
    });
    let selectedOption;
    if (dropsLoaded) {
      drop = drops.find(x => x.uuid === id);
      if (drop) {
        product = drop.product;
        if (product) {
          productId = product.uuid;
        }
        dropReward = drop.reward;
        faqs = drop.faqs;
        socialPosts = drop.social_posts;
        potentialSweepstakesWinners = drop.potential_sweepstakes_winners;
        sweepstakesWinners = drop.sweepstakes_winners;
        userEntries = drop.user_entries;
        potentialSweepstakesWinnersCount = potentialSweepstakesWinners.length;
        sweepstakesWinnersCount = sweepstakesWinners.length;
        userEntriesCount = userEntries.length;
        console.log("FAQS");
        console.log("hey")
        console.log(faqs);
        console.log("PRODUCTID");
        console.log(productId);
        console.log("SOCIAL POSTS");
        console.log(socialPosts);

        if (productId) {
          selectedOption = options.filter(option => option.value === productId);
          console.log("SELECTEDO OPTION");
          console.log(selectedOption);
        }
        if (drop.start_date) {
          startDate = moment.utc(drop.start_date).toDate();
        }
        if (drop.end_date) {
          endDate = moment.utc(drop.end_date).toDate();
        }
        if (drop.estimated_delivery_date) {
          deliveryDate = moment.utc(drop.estimated_delivery_date).toDate();
        }
        if (dropReward) {
          dropRewardImageUrl = drop.reward_image_url;
          dropRewardImageStyle = {
            backgroundImage: `url(${dropRewardImageUrl})`
          };
        }
        badgeUrl = drop.badge_url;
        badgeImageStyle = {
          backgroundImage: `url(${badgeUrl})`
        };
        dropImageUrl = drop.image_url;
        dropImageStyle = {
          backgroundImage: `url(${dropImageUrl})`
        };
        dropLevels = drop.drop_levels;
        if (this.state.showEditAchievement && dropLevels.length && this.props.currentAchievement) {
          dropLevel = dropLevels.find(x => x.uuid === this.state.levelId);
          if (dropLevel) {
            let achievements = dropLevel.achievements;
            achievement = achievements.find(x => x.uuid === this.props.currentAchievement);
            if (achievement) {
              achievementImageUrl = achievement.image_url;
              achievementImageStyle = {
                backgroundImage: `url(${achievementImageUrl})`
              };
              rewardUrl = achievement.reward_image_url;
              rewardImageStyle = {
                backgroundImage: `url(${rewardUrl})`
              };
              infoImageUrl = achievement.info_image_url;
              infoImageStyle = {
                backgroundImage: `url(${infoImageUrl})`
              };
            }
          }
        } else if (this.state.showLevelSettings && dropLevels.length) {
          dropLevel = dropLevels.find(x => x.uuid === this.state.levelId);
          if (dropLevel.product_uuid) {
            selectedLevelProductOption = options.filter(option => option.value === dropLevel.product_uuid);
          }
          levelRewardImageUrl = dropLevel.reward_image_url;
          levelRewardImageStyle = {
            backgroundImage: `url(${levelRewardImageUrl})`
          };
        }
      }
    }

    let referral = false;
    let share = false;
    let smsSignup = false;
    let emailSignup = false;
    let quiz = false;
    let game = false;
    let textField = false;
    let achievementOptions = [
      { label: "Referral", value: "referral" },
      { label: "Share", value: "share" },
      { label: "Text Field", value: "text" },
      { label: "Email Signup", value: "email_signup" },
      { label: "SMS Signup", value: "sms_signup" },
      { label: "Quiz", value: "quiz" },
      { label: "Game", value: "game" },
    ];
    let socialOptions = [
      { label: "Instagram", value: "instagram" },
      { label: "Twitter", value: "twitter" },
      { label: "Facebook", value: "facebook" }
    ];
    let selectedAchievementOption;
    let selectedAchievementProductOption;
    if (achievement) {
      selectedAchievementOption = achievementOptions.filter(option => option.value === achievement.achievement_type);
      if (achievement.product_uuid) {
        selectedAchievementProductOption = options.filter(option => option.value === achievement.product_uuid);
      }
      if ((achievement.achievement_type === "referral" || this.state.achievementFieldsToUpdate["achievement_type"] === "referral") && this.state.achievementFieldsToUpdate["achievement_type"] != "share" && this.state.achievementFieldsToUpdate["achievement_type"] != "text"
        && this.state.achievementFieldsToUpdate["achievement_type"] != "text" && this.state.achievementFieldsToUpdate["achievement_type"] != "email_signup" && this.state.achievementFieldsToUpdate["achievement_type"] != "sms_signup"
        && this.state.achievementFieldsToUpdate["achievement_type"] != "quiz" && this.state.achievementFieldsToUpdate["achievement_type"] != "game") {
        referral = true;
      }
      if ((achievement.achievement_type === "share" || this.state.achievementFieldsToUpdate["achievement_type"] === "share") && this.state.achievementFieldsToUpdate["achievement_type"] != "referral" && this.state.achievementFieldsToUpdate["achievement_type"] != "text"
        && this.state.achievementFieldsToUpdate["achievement_type"] != "text" && this.state.achievementFieldsToUpdate["achievement_type"] != "email_signup" && this.state.achievementFieldsToUpdate["achievement_type"] != "sms_signup"
        && this.state.achievementFieldsToUpdate["achievement_type"] != "quiz" && this.state.achievementFieldsToUpdate["achievement_type"] != "game") {
        share = true;
      }
      if ((achievement.achievement_type === "email_signup" || this.state.achievementFieldsToUpdate["achievement_type"] === "email_signup") && this.state.achievementFieldsToUpdate["achievement_type"] != "referral" && this.state.achievementFieldsToUpdate["achievement_type"] != "text"
        && this.state.achievementFieldsToUpdate["achievement_type"] != "text" && this.state.achievementFieldsToUpdate["achievement_type"] != "share" && this.state.achievementFieldsToUpdate["achievement_type"] != "sms_signup"
        && this.state.achievementFieldsToUpdate["achievement_type"] != "quiz" && this.state.achievementFieldsToUpdate["achievement_type"] != "game") {
        emailSignup = true;
      }
      if ((achievement.achievement_type === "sms_signup" || this.state.achievementFieldsToUpdate["achievement_type"] === "sms_signup") && this.state.achievementFieldsToUpdate["achievement_type"] != "referral" && this.state.achievementFieldsToUpdate["achievement_type"] != "text"
        && this.state.achievementFieldsToUpdate["achievement_type"] != "text" && this.state.achievementFieldsToUpdate["achievement_type"] != "share" && this.state.achievementFieldsToUpdate["achievement_type"] != "email_signup"
        && this.state.achievementFieldsToUpdate["achievement_type"] != "quiz" && this.state.achievementFieldsToUpdate["achievement_type"] != "game") {
        smsSignup = true;
      }
      if ((achievement.achievement_type === "quiz" || this.state.achievementFieldsToUpdate["achievement_type"] === "quiz") && this.state.achievementFieldsToUpdate["achievement_type"] != "referral" && this.state.achievementFieldsToUpdate["achievement_type"] != "text"
        && this.state.achievementFieldsToUpdate["achievement_type"] != "text" && this.state.achievementFieldsToUpdate["achievement_type"] != "share" && this.state.achievementFieldsToUpdate["achievement_type"] != "sms_signup"
        && this.state.achievementFieldsToUpdate["achievement_type"] != "email_signup" && this.state.achievementFieldsToUpdate["achievement_type"] != "game") {
        quiz = true;
      }
      if ((achievement.achievement_type === "game" || this.state.achievementFieldsToUpdate["achievement_type"] === "game") && this.state.achievementFieldsToUpdate["achievement_type"] != "referral" && this.state.achievementFieldsToUpdate["achievement_type"] != "text"
        && this.state.achievementFieldsToUpdate["achievement_type"] != "text" && this.state.achievementFieldsToUpdate["achievement_type"] != "share" && this.state.achievementFieldsToUpdate["achievement_type"] != "sms_signup"
        && this.state.achievementFieldsToUpdate["achievement_type"] != "quiz" && this.state.achievementFieldsToUpdate["achievement_type"] != "email_signup") {
        game = true;
      }
      if ((achievement.achievement_type === "text" || this.state.achievementFieldsToUpdate["achievement_type"] === "text") && this.state.achievementFieldsToUpdate["achievement_type"] != "referral"
        && this.state.achievementFieldsToUpdate["achievement_type"] != "game" && this.state.achievementFieldsToUpdate["achievement_type"] != "share" && this.state.achievementFieldsToUpdate["achievement_type"] != "sms_signup"
        && this.state.achievementFieldsToUpdate["achievement_type"] != "quiz" && this.state.achievementFieldsToUpdate["achievement_type"] != "email_signup") {
        textField = true;
      }
      if (achievement.tags) {
        achievementTags = achievement.tags.split(",");
      }
    }
    return (
      <div name="Drop" id="drop">
        <div id="hero-logo-container">
          <Link to="/control-tower">
            <img id="hero-logo" alt="Hero Logo" src="/h-mark.svg" />
          </Link>
        </div>
        { (loaded && drop) ? (
          <div id="page-nav">
            {this.state.showBasicInfo ? (
              <div onClick={this.openNavItem.bind(this, "basic")} className="active-nav-btn">Basic Info</div>
            ) : (
              <div onClick={this.openNavItem.bind(this, "basic")} className="nav-btn">Basic Info</div>
            )}
            {this.state.showAssets ? (
              <div onClick={this.openNavItem.bind(this, "assets")} className="active-nav-btn">Assets</div>
            ) : (
              <div onClick={this.openNavItem.bind(this, "assets")} className="nav-btn">Assets</div>
            )}
            {drop.playable ? (
              <div>
                {this.state.showAchievements || this.state.showNewAchievement || this.state.showEditAchievement || this.state.showLevelSettings ? (
                  <div onClick={this.openNavItem.bind(this, "achievements")} className="active-nav-btn">Achievements</div>
                ) : (
                  <div onClick={this.openNavItem.bind(this, "achievements")} className="nav-btn">Achievements</div>
                )}
              </div>
            ) : (
              <span/>
            )}
            {this.state.showFAQs ? (
              <div onClick={this.openNavItem.bind(this, "faqs")} className="active-nav-btn">FAQs</div>
            ) : (
              <div onClick={this.openNavItem.bind(this, "faqs")} className="nav-btn">FAQs</div>
            )}
            {drop.giveaway ? (
              <div>
                {this.state.showSweepstakes ? (
                  <div onClick={this.openNavItem.bind(this, "sweepstakes")} className="active-nav-btn">Sweepstakes</div>
                ) : (
                  <div onClick={this.openNavItem.bind(this, "sweepstakes")} className="nav-btn">Sweepstakes</div>
                )}
              </div>
            ) : (
              <span/>
            )}
          </div>
        ) : (
          <span></span>
        )}
        {loaded ? (
          <div id="drop-container">
            { ( loaded && drop )? (
              <div id="drop-info">
                { this.state.canSave ? (
                  <div className="save-btn" id="save" onClick={this.save.bind(this)}>
                    <div>{saveEmoji()}</div>
                  </div>
                ) : (
                  <span></span>
                )}
                {/* DROP INFO */}
                {this.state.showBasicInfo ? (
                  <div>
                    <section className="drop-section">
                      <label>Basic Info</label>
                      <div className="drop-details">
                        <div id="basic-info">
                          <form>
                            <div className="full-input">
                              <div className="select-container">
                                <div className="input-container">
                                  <div><label>Product</label></div>
                                </div>
                                <Select onChange={this.setProduct.bind(this)} 
                                  options={options}
                                  defaultValue={selectedOption}
                                  menuPortalTarget={document.body}
                                  menuPosition={'fixed'}
                                />
                              </div>
                              <div className="input-container">
                                <div><label>Drop Title</label></div>
                                <input name="title" id="drop-title-input" placeholder={"e.g. Bread Box"} defaultValue={drop.title} onChange={this.prepareInput.bind(this)}></input>
                              </div> 
                              <div className="input-container">
                                <div><label>Drop Description</label></div>
                                <textarea name="description" id="drop-description-input" defaultValue={drop.description} onChange={this.prepareInput.bind(this)}></textarea>
                              </div>
                              <div className="input-container">
                                <div><label>Drop Sub Description</label></div>
                                <textarea name="sub_description" id="drop-sub-description-input" defaultValue={drop.sub_description} onChange={this.prepareInput.bind(this)}></textarea>
                              </div>
                              <div className="input-container">
                                <div><label>Slug</label></div>
                                <input name="slug" id="drop-slug-input" defaultValue={drop.slug} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Page Title</label></div>
                                <input name="page_title" id="drop-page-title-input" placeholder={"e.g. Hero Roll"} defaultValue={drop.page_title} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              {drop.giveaway ? (
                                <div className="input-container">
                                  <div><label>Sweepstakes Title</label></div>
                                  <input name="sweepstakes_title" id="drop-sweepstakes-title-input" placeholder={"e.g. Hero Bread"} defaultValue={drop.sweepstakes_title} onChange={this.prepareInput.bind(this)}></input>
                                </div>
                              ) : (
                                <span/>
                              )}
                              {drop.giveaway ? (
                                <div className="input-container">
                                  <div><label>Sweepstakes Subtitle</label></div>
                                  <input name="sweepstakes_subtitle" id="drop-sweepstakes-subtitle-input" placeholder={"e.g. Launch Sweepstakes"} defaultValue={drop.sweepstakes_subtitle} onChange={this.prepareInput.bind(this)}></input>
                                </div>
                              ) : (
                                <span/>
                              )}
                              {drop.giveaway ? (
                                <div className="input-container">
                                  <div><label>Sweepstakes Description</label></div>
                                  <textarea name="sweepstakes_description" id="drop-sweepstakes-description-input" placeholder={"e.g. Enter the information below to..."} defaultValue={drop.sweepstakes_description} onChange={this.prepareInput.bind(this)}></textarea>
                                </div>
                              ) : (
                                <span />
                              )}
                              {drop.giveaway ? (
                                <div className="input-container">
                                  <div><label>Sweepstakes CTA Text</label></div>
                                  <input name="sweepstakes_cta_text" id="drop-sweepstakes-cta_text-input" placeholder={"e.g. WIN IT"} defaultValue={drop.sweepstakes_cta_text} onChange={this.prepareInput.bind(this)}></input>
                                </div>
                              ) : (
                                <span/>
                              )}
                              {drop.giveaway ? (
                                <div className="input-container">
                                  <div><label>Sweepstakes About Text</label></div>
                                  <input name="sweepstakes_about_text" id="drop-sweepstakes-about_text-input" placeholder={"e.g. WHAT'S THIS?"} defaultValue={drop.sweepstakes_about_text} onChange={this.prepareInput.bind(this)}></input>
                                </div>
                              ) : (
                                <span/>
                              )}
                              {drop.giveaway ? (
                                <div className="input-container">
                                  <div><label>Sweepstakes Rules, Terms, Privacy</label></div>
                                  <textarea name="sweepstakes_rules_terms_and_privacy" id="drop-sweepstakes-rules-input" placeholder={"e.g. Please read the following terms and conditions..."} defaultValue={drop.sweepstakes_rules_terms_and_privacy} onChange={this.prepareInput.bind(this)}></textarea>
                                </div>
                              ) : (
                                <span/>
                              )}
                              {drop.giveaway ? (
                                <div className="input-container">
                                  <div><label>Sweepstakes Rules, Terms, Privacy - Short Version</label></div>
                                  <textarea name="sweepstakes_rules_terms_and_privacy_short_version" id="drop-sweepstakes-rules-input" placeholder={"e.g. NO PURCHASE OR PAYMENT NECESSARY. Limit 1 entry per person..."} defaultValue={drop.sweepstakes_rules_terms_and_privacy_short_version} onChange={this.prepareInput.bind(this)}></textarea>
                                </div>
                              ) : (
                                <span/>
                              )}
                              {drop.giveaway ? (
                                <div className="input-container">
                                  <div><label>Sweepstakes Prize Description</label></div>
                                  <textarea name="sweepstakes_prize_description" id="drop-sweepstakes-prize-input" placeholder={"e.g. 1 bag of Hero Rolls, 1 bucket hat..."} defaultValue={drop.sweepstakes_prize_description} onChange={this.prepareInput.bind(this)}></textarea>
                                </div>
                              ) : (
                                <span />
                              )}
                              {drop.giveaway ? (
                                <div className="input-container">
                                  <div><label>Background Video</label></div>
                                  <input name="background_video" id="drop-background-video-url-input" placeholder={"e.g. S3 video URL"} defaultValue={drop.background_video} onChange={this.prepareInput.bind(this)}></input>
                                </div>
                              ) : (
                                <span/>
                              )}
                              {drop.giveaway ? (
                                <div className="input-container">
                                  <div><label>Main Video</label></div>
                                  <input name="main_video" id="drop-main-video-url-input" placeholder={"e.g. S3 video URL"} defaultValue={drop.main_video} onChange={this.prepareInput.bind(this)}></input>
                                </div>
                              ) : (
                                <span/>
                              )}
                              {drop.playable ? (
                                <div className="input-container">
                                  <div><label>Waitlist Title</label></div>
                                  <input name="waitlist_title" id="drop-waitlist-title-input" placeholder={"e.g. The Bread Queue"} defaultValue={drop.waitlist_title} onChange={this.prepareInput.bind(this)}></input>
                                </div>
                              ) : (
                                <span/>
                              )}
                              {drop.playable ? (
                                <div className="input-container">
                                  <div><label>Waitlist Description</label></div>
                                  <textarea name="waitlist_description" id="drop-waitlist-description-input" defaultValue={drop.waitlist_description} onChange={this.prepareInput.bind(this)}></textarea>
                                </div>
                              ) : (
                                <span/>
                              )}                            
                              <div className="input-container">
                                <div><label>CTA Text</label></div>
                                <input name="cta_button_text" id="drop-cta-text-input" placeholder={"e.g. Pre-order Now"} defaultValue={drop.cta_button_text} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              {drop.display_social_posts ? (
                                <div className="input-container">
                                  <div><label>Social Banner Text</label></div>
                                  <input name="social_banner_text" id="drop-stock-cta-text-input" placeholder={"e.g. WHO'S MENTIONING HERO?"} defaultValue={drop.social_banner_text} onChange={this.prepareInput.bind(this)}></input>
                                </div>
                              ) : (
                                <span />
                              )}
                              {drop.limited ? (
                              <div className="input-container">
                                <div><label>Out of Stock CTA Text</label></div>
                                <input name="out_of_stock_cta_text" id="drop-stock-cta-text-input" placeholder={"e.g. Tell Me When It's Back"} defaultValue={drop.out_of_stock_cta_text} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              ) : (
                                <span/>
                              )}
                              {drop.limited ? (
                                <div className="input-container">
                                  <div><label>Out of Stock Message</label></div>
                                  <input name="out_of_stock_message" id="drop-stock-message-input" placeholder={"e.g. Out of Stock"} defaultValue={drop.out_of_stock_cta_text} onChange={this.prepareInput.bind(this)}></input>
                                </div>
                              ) : (
                                <span/>
                              )}
                              {drop.locations ? (
                                <div className="input-container">
                                  <div><label>Locations CTA Text</label></div>
                                  <input name="locations_cta_text" id="drop-locations-cta-input" placeholder={"e.g. FIND STORES"} defaultValue={drop.locations_cta_text} onChange={this.prepareInput.bind(this)}></input>
                                </div>
                              ) : (
                                <span/>
                              )}
                              {drop.locations ? (
                                <div className="input-container">
                                  <div><label>Locations Description</label></div>
                                  <input name="locations_description" id="drop-locations-cta-input" placeholder={"e.g. See which locations are building on Hero Bread"} defaultValue={drop.locations_description} onChange={this.prepareInput.bind(this)}></input>
                                </div>
                              ) : (
                                <span/>
                              )}
                            </div>
                            <div className="split-input">
                              <div className="split-left">
                                <div className="input-container">
                                  <div><label>Start Date</label></div>
                                  <DatePicker
                                    selected={startDate}
                                    onChange={this.handleStartDateChange.bind(this)}
                                    timeFormat="hh:mm"
                                    showTimeSelect
                                    dateFormat="MMMM d, yyyy hh:mm aa"
                                    placeholderText="Click to select"
                                  />
                                </div>
                              </div>
                              <div className="split-right">
                                <div className="input-container">
                                  <div><label>End Date</label></div>
                                  <DatePicker
                                    selected={endDate}
                                    onChange={this.handleEndDateChange.bind(this)}
                                    timeFormat="hh:mm"
                                    showTimeSelect
                                    dateFormat="MMMM d, yyyy hh:mm aa"
                                    placeholderText="Click to select"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="full-input">
                              <div className="input-container">
                                <div><label>Estimated Delivery Date</label></div>
                                <DatePicker
                                  selected={deliveryDate}
                                  onChange={this.handleDeliveryDateChange.bind(this)}
                                  timeFormat="hh:mm"
                                  dateFormat="MMMM d, yyyy"
                                  placeholderText="Click to select"
                                />
                              </div>
                            </div>
                            <div className="split-input">
                              <div className="split-left">
                                <div className={`input-container inline-input-container`}>
                                  <div><label>Active</label></div>
                                  <div className="checkbox-toggle">
                                    <input className="checkbox-toggle__input" name="active" id="active" type="checkbox" onChange={this.prepareCheckbox.bind(this)} defaultChecked={drop.active} />
                                    <label htmlFor="active" className="checkbox-toggle__label" />
                                  </div>
                                </div>
                                <div className={`input-container inline-input-container`}>
                                  <div><label>Reward</label></div>
                                  <div className="checkbox-toggle">
                                    <input className="checkbox-toggle__input" name="reward" id="reward" type="checkbox" onChange={this.prepareCheckbox.bind(this)} defaultChecked={drop.reward} />
                                    <label htmlFor="reward" className="checkbox-toggle__label" />
                                  </div>
                                </div>
                                <div className={`input-container inline-input-container`}>
                                  <div><label>Playable</label></div>
                                  <div className="checkbox-toggle">
                                    <input className="checkbox-toggle__input" name="playable" id="playable" type="checkbox" onChange={this.prepareCheckbox.bind(this)} defaultChecked={drop.playable} />
                                    <label htmlFor="playable" className="checkbox-toggle__label" />
                                  </div>
                                </div>
                                <div className={`input-container inline-input-container`}>
                                  <div><label>Giveaway</label></div>
                                  <div className="checkbox-toggle">
                                    <input className="checkbox-toggle__input" name="giveaway" id="giveaway" type="checkbox" onChange={this.prepareCheckbox.bind(this)} defaultChecked={drop.giveaway} />
                                    <label htmlFor="giveaway" className="checkbox-toggle__label" />
                                  </div>
                                </div>
                                <div className={`input-container inline-input-container`}>
                                  <div><label>Limited</label></div>
                                  <div className="checkbox-toggle">
                                    <input className="checkbox-toggle__input" name="limited" id="limited" type="checkbox" onChange={this.prepareCheckbox.bind(this)} defaultChecked={drop.limited} />
                                    <label htmlFor="limited" className="checkbox-toggle__label" />
                                  </div>
                                </div>
                                <div className={`input-container inline-input-container`}>
                                  <div><label>Locations</label></div>
                                  <div className="checkbox-toggle">
                                    <input className="checkbox-toggle__input" name="locations" id="locations" type="checkbox" onChange={this.prepareCheckbox.bind(this)} defaultChecked={drop.locations} />
                                    <label htmlFor="locations" className="checkbox-toggle__label" />
                                  </div>
                                </div>
                                <div className={`input-container inline-input-container`}>
                                  <div><label>Social Posts</label></div>
                                  <div className="checkbox-toggle">
                                    <input className="checkbox-toggle__input" name="display_social_posts" id="display_social_posts" type="checkbox" onChange={this.prepareCheckbox.bind(this)} defaultChecked={drop.display_social_posts} />
                                    <label htmlFor="display_social_posts" className="checkbox-toggle__label" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </section>
                    {/* BEING DROP REWARD SECTION */}
                    {(drop && drop.reward) ? (
                      <section className="drop-section">
                        <label>Reward</label>
                        <div className="drop-level-details">
                          <div className="split-input">
                            <div className="split-left">
                              <div className="input-container">
                                <div><label>Title</label></div>
                                <input name="reward_title" id="achievement-title-input" placeholder={"e.g. Tote bag"} defaultValue={drop.reward_title} onChange={this.prepareInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Description</label></div>
                                <textarea name="reward_description" id="drop-tldr-input" placeholder={"e.g. Congrats! Not only did you just pre-order a Hero™ Bread Box, but you also unlocked this swag reward..."} defaultValue={drop.reward_description} onChange={this.prepareInput.bind(this)}></textarea>
                              </div>
                            </div>
                            <div className="split-right">
                              <div className="input-container">
                                <div className="sub-section-title">
                                  <label>Image</label>
                                  <input type="file" id="reward-image" data-id={drop.uuid} onChange={this.prepareRewardImage} />
                                  {!dropRewardImageUrl ? (
                                    <label id="file-upload-btn" htmlFor="reward-image">Add +</label>
                                  ) : (
                                    <span></span>
                                  )}
                                </div>
                                {dropRewardImageUrl ? (
                                  <div className="drop-image">
                                    <div className="image-container">
                                      <div style={dropRewardImageStyle} className="drop-image"></div>
                                      <div data-drop_id={drop.uuid} className="delete-btn" onClick={this.deleteRewardImage.bind(this)}>Delete</div>
                                    </div>
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    ) : (
                      <span></span>
                    )}
                    {/* END EDIT DROP REWARD SECTION */}


                    {/* BEING DROP SOCIAL POSTS */}
                    {(drop && drop.display_social_posts) ? (
                      <div id="social-posts">
                        {this.state.showAddSocialPost ? (
                          <section className="drop-section">
                            <label>New Social Post</label>
                            { canSaveSocialPost ? (
                              <div className="save-btn" id="save" onClick={this.saveSocialPost.bind(this)}>
                                <div>{saveEmoji()}</div>
                              </div>
                            ) : (
                              <span></span>
                            )}
                            <div className="drop-level-details">
                              <div className="input-container">
                                <div><label>Post URL</label></div>
                                <input name="post_url" id="achievement-title-input" placeholder={"e.g. https://www.instagram.com/p/CU9dBT2Aa0y"} defaultValue={""} onChange={this.setSocialPostUrl.bind(this)}></input>
                              </div>
                              <div className="select-container">
                                <div className="select-input-container">
                                  <div><label>Platform</label></div>
                                </div>
                                <Select onChange={this.setSocialPostPlatform.bind(this)}
                                  options={socialOptions}
                                  defaultValue={this.state.newSocialPostPlatform}
                                />
                              </div>
                              <div className="input-container">
                                <label>Image</label>
                                <input type="file" id="post-image" data-id={id} onChange={this.prepareSocialPostImage.bind(this)} />
                              </div>
                            </div>
                          </section>
                        ) : (
                          <section className="drop-section">
                          <label>Social Posts</label>
                          <label id="add-social-post-btn" onClick={this.showAddSocialPost.bind(this)}>Add +</label>
                          { socialPosts.map((post, i) => {
                            let postImageStyle = {
                              backgroundImage: `url(${post.image_url})`
                            };
                            return (
                              <div className="drop-level-details">
                                <div className="split-input">
                                  <div className="split-left">
                                    <div className="input-container">
                                      <div><label className="post-platform">{post.platform}</label></div>
                                    </div>
                                  </div>
                                  <div className="split-right">
                                    <a href={post.post_url} target="_blank">
                                      <div style={postImageStyle} className="post-image"></div>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </section>
                        )}
                      </div>
                    ) : (
                      <span></span>
                    )}
                    {/* END EDIT DROP SOCIAL POSTS */}






                  </div>
  
                ) : (
                  <span></span>
                )}
                {/* END DROP INFO */}
                {/* ASSETS */}
                {this.state.showAssets ? (
                  <section className="drop-section">
                    <label>Assets</label>
                    <div className="drop-details">
                      <div id="assets">
                        <form>
                          {/* <div className="full-input">
                            <div className="input-container">
                              <div><label>3D File URL</label></div>
                              <input name="three_dimensional_asset_url" id="drop-3d-url-input" defaultValue={drop.three_dimensional_asset_url} onChange={this.prepareInput.bind(this)}></input>
                            </div>
                          </div> */}
                          <div className="full-input">
                            <div className="input-container">
                              <div className="sub-section-title">
                                <label>Main Image</label>
                                <input type="file" id="drop-image" data-id={id} onChange={this.prepareDropImage} />
                                {!dropImageUrl ? (
                                  <label id="file-upload-btn" htmlFor="drop-image">Add +</label>
                                ) : (
                                  <span></span>
                                )}
                              </div>
                            </div>
                            {dropImageUrl ? (
                              <div id="drop-images">
                                <div className="image-container">
                                  <div style={dropImageStyle} className="drop-image"></div>
                                  <div data-drop_id={id} className="delete-btn" onClick={this.deleteDropImage.bind(this)}>Delete</div>
                                </div>
                              </div>
                            ) : (
                              <span></span>
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </section>
                  
                ) : (
                  <span></span>
                )}
                {/* END ASSETS */}
                {/* ACHIEVEMENTS */}
                {this.state.showAchievements ? (
                  <section className="drop-section">
                    <label>Achievements</label>
                    <div id="add-level-btn" onClick={this.addDropLevel.bind(this)}>Add Level</div>
                    { dropLevels.length ? (
                      <div id="levels">
                        { dropLevels.map((level, i) => {
                          let achievements = level.achievements;
                          return (
                            <div className="drop-level-details">
                              <div className="level-header">
                                <label>Level {level.number}</label>
                                <div className="level-settings" onClick={this.editLevel.bind(this, level.uuid, level.number, drop.uuid)}>
                                  <img src="/settings.svg" />
                                </div>
                                <div className="add-achievement-btn" onClick={this.newAchievement.bind(this, level.uuid, level.number)}>
                                  <span>Add Achievement</span>
                                </div>
                              </div>
                              { achievements.length ? (
                                <div className="level-achievements">
                                  { achievements.map((achievement, i) => {
                                    let title = achievement.title;
                                    let picUrl = null;
                                    let base = baseURL();
                                    if (achievement.image_url) {
                                      picUrl = `${achievement.image_url}`;
                                    } else {
                                      picUrl = "/sad-icon.png";
                                    }
                                    return (
                                      <div className="achievement" key={i} data-achievement_id={achievement.uuid} onClick={this.editDropAchievement.bind(this, level.uuid, level.number, achievement.uuid)}>
                                        <div className="hero-achievement-container">
                                          <div name="hero-achievement-image" className="hero-achievement-image-container">
                                            <img alt="hero achievement" src={picUrl} />
                                            <div className="achievement-details">
                                              <div className="achievement-title">{title}</div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : (
                                <span></span>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="no-levels">
                        No levels found
                      </div>
                    )}
                  </section>
                ) : (
                  <span></span>
                )}
                {/* END ACHIEVEMENTS */}
                {/* NEW ACHIEVEMENT */}
                {this.state.showNewAchievement ? (
                  <section className="drop-section">
                    <div className="level-header">
                      <div className="stick-left">
                        <label>New Achievement</label>
                        <div className="level-number-sub-text">Level {this.state.levelNumber}</div>
                      </div>
                      <div className="cancel-btn" onClick={this.openNavItem.bind(this, "achievements")}>
                        Cancel
                      </div>
                      {this.state.canSaveAchievement ? (
                        <div className="save-btn" id="save-achievement" onClick={this.addDropAchievement.bind(this)}>
                          <div>{saveEmoji()}</div>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <div className="drop-level-details">
                      <div className="input-container">
                        <div><label>Achievement Title</label></div>
                        <input name="title" id="achievement-title-input" placeholder={"e.g. Refer one friend"} defaultValue={""} onChange={this.prepareAchievementInput.bind(this)}></input>
                      </div>
                    </div>
                  </section>
                ) : (
                  <span></span>
                )}
                {/* END NEW ACHIEVEMENT */}
                {/* EDIT ACHIEVEMENT */}
                {this.state.showEditAchievement ? (
                  <div className="multi-section-container">
                    <section className="drop-section">
                      <div className="level-header">
                        <div className="stick-left">
                          <label>Edit Achievement</label>
                          <div className="level-number-sub-text">Level {this.state.levelNumber}</div>
                        </div>
                        {this.state.canSaveAchievement ? (
                          <div className="save-btn" id="save-achievement" onClick={this.saveDropAchievement.bind(this)}>
                            <div>{saveEmoji()}</div>
                          </div>
                        ) : (
                          <span></span>
                        )}
                        <div className="cancel-btn" onClick={this.openNavItem.bind(this, "achievements")}>
                          Back
                        </div>
                        {achievement ? (
                          <div data-achievement_id={achievement.uuid} className="delete-btn" onClick={this.confirmDeleteAchievement.bind(this)}>Delete</div>
                        ) : (
                          <span></span>
                        )}
                      </div>
                      {achievement ? (
                        <div className="drop-level-details">
                          {this.state.showConfirmDeleteAchievement ? (
                            <div id="confirm-delete">
                              <div id="delete-description">
                                Are you sure you want to delete this achievement?
                              </div>
                              <div id="delete-description-info">
                                Any users tied to this level may experience horrendous side-effects / bugs upon deletion.
                              </div>
                              <div id="delete-description-info">
                                Do not do this, unless you are absolutely sure what you are doing.
                              </div>
                              <div data-drop_id={id} className="delete-btn" onClick={this.deleteDropAchievement.bind(this, achievement.uuid)}>Yes, Delete</div>
                            </div>
                          ) : (
                            <div className="split-input">
                              <div className="split-left">
                                <div className="input-container">
                                  <div><label>Title</label></div>
                                  <input name="title" id="achievement-title-input" placeholder={"e.g. Refer one friend"} defaultValue={achievement.title} onChange={this.prepareAchievementInput.bind(this)}></input>
                                </div>
                                {referral || emailSignup || smsSignup || quiz ? (
                                  <span></span>
                                ) : (
                                  <div className="input-container">
                                    <div><label>URL</label></div>
                                    <input name="url" id="achievement-url-input" placeholder={"e.g. Instagram page, game, etc."} defaultValue={achievement.url} onChange={this.prepareAchievementInput.bind(this)}></input>
                                  </div>
                                )}
                                {textField ? (
                                  <div className="input-container">
                                    <div><label>URL CTA</label></div>
                                    <input name="url_cta" id="achievement-url-cta-input" placeholder={"e.g. Visit"} defaultValue={achievement.url_cta} onChange={this.prepareAchievementInput.bind(this)}></input>
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                                {textField ? (
                                  <div className="input-container">
                                    <div><label>Input Prefix</label></div>
                                    <input name="prefix" id="achievement-prefix-input" placeholder={"e.g. @"} defaultValue={achievement.prefix} onChange={this.prepareAchievementInput.bind(this)}></input>
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                                {textField ? (
                                  <div className="input-container">
                                    <div><label>Input Placeholder</label></div>
                                    <input name="placeholder" id="achievement-placeholder-input" placeholder={"e.g. username"} defaultValue={achievement.placeholder} onChange={this.prepareAchievementInput.bind(this)}></input>
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                                <div className="input-container">
                                  <div><label>Info TLDR</label></div>
                                  <textarea name="info_tldr" id="achievement-tldr-input" placeholder={"i.e. Bolded summary in achievement info modal"} defaultValue={achievement.info_tldr} onChange={this.prepareAchievementInput.bind(this)}></textarea>
                                </div>
                                <div className="input-container">
                                  <div><label>Info Description</label></div>
                                  <textarea name="info_description" id="achievement-description-input" placeholder={"i.e. Longer (gray) description in achievement info modal"} defaultValue={achievement.info_description} onChange={this.prepareAchievementInput.bind(this)}></textarea>
                                </div>
                                <div className="input-container">
                                  <div><label>CSS</label></div>
                                  <input name="css" id="achievement-css-input" placeholder={"e.g. background: linear-gradient(107.84deg, #8C79FF 22.44%, #FFA979 85.6%);"} defaultValue={achievement.css} onChange={this.prepareAchievementInput.bind(this)}></input>
                                </div>
                              </div>
                              <div className="split-right">
                                <div className="select-container">
                                  <div className="select-input-container">
                                    <div><label>Type</label></div>
                                  </div>
                                  <Select onChange={this.setAchievementType.bind(this)}
                                    options={achievementOptions}
                                    defaultValue={selectedAchievementOption}
                                  />
                                </div>
                                {game ? (
                                  <div className="input-container">
                                    <div><label>Minimum Score Required</label></div>
                                    <input name="minimum_score_required" id="achievement-min-score-required-input" placeholder={"Must enter at least 1, digits only"} defaultValue={achievement.minimum_score_required} onChange={this.prepareAchievementInput.bind(this)}></input>
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                                {referral ? (
                                  <div className="input-container">
                                    <div><label>Referrals Needed</label></div>
                                    <input name="referrals_needed" id="achievement-referrals-needed-input" placeholder={"Must enter at least 1, digits only"} defaultValue={achievement.referrals_needed} onChange={this.prepareAchievementInput.bind(this)}></input>
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                                {share ? (
                                  <div className="input-container">
                                    <div><label>Visits Needed</label></div>
                                    <input name="referrals_needed" id="achievement-referrals-needed-input" placeholder={"Must enter at least 1, digits only"} defaultValue={achievement.referrals_needed} onChange={this.prepareAchievementInput.bind(this)}></input>
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                                <div className="input-container">
                                  <div><label>Hidden</label></div>
                                  <div className="checkbox-toggle">
                                    <input className="checkbox-toggle__input" name="hidden" id="hidden" type="checkbox" onChange={this.prepareAchievementCheckbox.bind(this)} defaultChecked={achievement.hidden} />
                                    <label htmlFor="hidden" className="checkbox-toggle__label" />
                                  </div>
                                </div>
                                {textField ? (
                                  <div className="input-container">
                                    <div><label>Auto-Approve</label></div>
                                    <div className="checkbox-toggle">
                                      <input className="checkbox-toggle__input" name="auto_approve" id="auto_approve" type="checkbox" onChange={this.prepareAchievementCheckbox.bind(this)} defaultChecked={achievement.auto_approve} />
                                      <label htmlFor="auto_approve" className="checkbox-toggle__label" />
                                    </div>
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                                {/* <div className="input-container">
                            <div><label>Reward</label></div>
                            <div className="checkbox-toggle">
                              <input className="checkbox-toggle__input" name="reward" id="reward" type="checkbox" onChange={this.prepareAchievementCheckbox.bind(this)} defaultChecked={achievement.reward} />
                              <label htmlFor="reward" className="checkbox-toggle__label" />
                            </div>
                          </div> */}
                                <div className="input-container">
                                  <div className="sub-section-title">
                                    <label>Image</label>
                                    <input type="file" id="icon-image" data-id={achievement.uuid} onChange={this.prepareAchievementImage} />
                                    {!achievementImageUrl ? (
                                      <label id="file-upload-btn" htmlFor="icon-image">Add +</label>
                                    ) : (
                                      <span></span>
                                    )}
                                  </div>
                                  {achievementImageUrl ? (
                                    <div className="achievement-image">
                                      <div className="image-container">
                                        <div style={achievementImageStyle} className="drop-image"></div>
                                        <div data-achievement_id={achievement.uuid} className="delete-btn" onClick={this.deleteAchievementImage.bind(this)}>Delete</div>
                                      </div>
                                    </div>
                                  ) : (
                                    <span></span>
                                  )}
                                </div>
                                <div className="input-container">
                                  <div><label>Tags</label></div>
                                  <input name="tags" id="achievement-tags-input" placeholder={""} data-achievement_id={achievement.uuid} defaultValue={""} onKeyPress={this.prepareAchievementTag.bind(this)}></input>
                                  { achievement.tags ? (
                                    <div id="achievement-tags-container">
                                      { achievementTags.map((tag, i) => {
                                        return (
                                          <div className="achievement-tag" data-achievement_id={achievement.uuid} data-tag={tag}>
                                            <div className="achievement-tag-name">
                                              {tag}
                                            </div>
                                            <div className="tag-delete">
                                              <div data-achievement_id={achievement.uuid} data-tag={tag} onClick={this.deleteTag.bind(this)}>x</div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  ) : (
                                    <span></span>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </section>

                    {/* BEGIN ACHIEVEMENT QUIZ SECTION */}
                    {(achievement && quiz) ? (
                      <section className="drop-section">
                        <label>Questions and Answers</label>
                        <div className="drop-level-details">
                          <div className="full-input">
                            <div className="input-container">
                              <div><label>Question 1</label></div>
                              <input name="achievement_question" id="achievement-question-input" placeholder={"e.g. What does Hero make?"} defaultValue={""} onChange={this.prepareAchievementInput.bind(this)}></input>
                            </div>
                          </div>
                          <div className="split-input">
                            <div className="split-left">
                              <div className="input-container">
                                <div><label>Answer</label></div>
                                <input name="achievement_answer" id="achievement-answer-input" placeholder={"e.g. Bread"} defaultValue={achievement.reward_description} onChange={this.prepareAchievementInput.bind(this)}></input>
                              </div>
                            </div>
                            <div className="split-right">
                              <div className="remove-achievement-answer">X</div>
                              <div className="correct-answer-container">
                                <div className="correct-answer-select">
                                </div>
                                <div className="correct-answer-text">
                                  <div>Right</div><div>Answer</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    ) : (
                      <span></span>
                    )}
                    {/* END ACHIEVEMENT QUIZ SECTION */}
                    {/* BEING ACHIEVEMENT REWARD SECTION */}
                    {(achievement && achievement.reward) ? (
                      <section className="drop-section">
                        <label>Reward</label>
                        {achievement ? (
                          <div className="drop-level-details">
                            <div className="split-input">
                              <div className="split-left">
                                <div className="input-container">
                                  <div><label>Title</label></div>
                                  <input name="reward_title" id="achievement-title-input" placeholder={"e.g. Tote bag"} defaultValue={achievement.reward_title} onChange={this.prepareAchievementInput.bind(this)}></input>
                                </div>
                                <div className="input-container">
                                  <div><label>Description</label></div>
                                  <textarea name="reward_description" id="achievement-tldr-input" placeholder={"e.g. Congrats! Not only did you just pre-order a Hero™ Bread Box, but you also unlocked this swag reward..."} defaultValue={achievement.reward_description} onChange={this.prepareAchievementInput.bind(this)}></textarea>
                                </div>
                              </div>
                              <div className="split-right">
                                <div className="select-container">
                                  <div className="select-input-container">
                                    <div><label>Product</label></div>
                                  </div>
                                  <Select onChange={this.setAchievementProduct.bind(this)}
                                    options={options}
                                    defaultValue={selectedAchievementProductOption}
                                    menuPortalTarget={document.body}
                                    menuPosition={'fixed'}
                                  />
                                </div>
                                <div className="input-container">
                                  <div className="sub-section-title">
                                    <label>Image</label>
                                    <input type="file" id="reward-image" data-id={achievement.uuid} onChange={this.prepareRewardImage} />
                                    {!rewardUrl ? (
                                      <label id="file-upload-btn" htmlFor="reward-image">Add +</label>
                                    ) : (
                                      <span></span>
                                    )}
                                  </div>
                                  {rewardUrl ? (
                                    <div className="achievement-image">
                                      <div className="image-container">
                                        <div style={rewardImageStyle} className="drop-image"></div>
                                        <div data-achievement_id={achievement.uuid} className="delete-btn" onClick={this.deleteRewardImage.bind(this)}>Delete</div>
                                      </div>
                                    </div>
                                  ) : (
                                    <span></span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <span></span>
                        )}
                      </section>

                    ) : (
                      <span></span>
                    )}
                    {/* END EDIT ACHIEVEMENT REWARD SECTION */}
                  </div>
                ) : (
                  <span></span>
                )}
                {/* END EDIT ACHIEVEMENT */}
                {/* LEVEL SETTINGS */}
                {this.state.showLevelSettings ? (
                  <div>
                    <section className="drop-section">
                      <div className="level-header">
                        <div className="stick-left">
                          <label>Settings</label>
                          <div className="level-number-sub-text">Level {this.state.levelNumber}</div>
                        </div>
                        <div className="cancel-btn" onClick={this.openNavItem.bind(this, "achievements")}>
                          Back
                        </div>
                        {this.state.canSaveLevel ? (
                          <div className="save-btn" id="save-achievement" onClick={this.saveLevel.bind(this)}>
                            <div>{saveEmoji()}</div>
                          </div>
                        ) : (
                          <span></span>
                        )}
                      </div>
                      <div className="drop-level-details">
                        {this.state.showConfirmDeleteLevel ? ( 
                          <div id="confirm-delete">
                            <div id="delete-description">
                              Are you sure you want to delete this level? 
                            </div>
                            <div id="delete-description-info">
                              Any users tied to this level may experience side-effects / bugs upon deletion. 
                            </div>
                            <div id="delete-description-info">
                              Do not do this, unless you are absolutely sure what you are doing.
                            </div>
                            <div data-drop_id={id} className="delete-btn" onClick={this.deleteDropLevel.bind(this, this.state.levelId)}>Yes, Delete</div>
                          </div>
                        ) : (
                          <div id="level-settings-info">
                            <div className="input-container">
                              <div><label>Number of Achievements Required to Complete</label></div>
                              <input name="number_of_achievements_to_complete" id="achievement-number-of-achievements-to-complete-input" placeholder={"Must be at least 1, digits only"} defaultValue={dropLevel.number_of_achievements_to_complete} onChange={this.prepareLevelInput.bind(this)}></input>
                            </div>
                            <div id="drop-level-reward-info">
                              <div className={`input-container inline-input-container`}>
                                <div><label>Reward</label></div>
                                <div className="checkbox-toggle">
                                  <input className="checkbox-toggle__input" name="reward" id="reward" type="checkbox" onChange={this.prepareLevelCheckbox.bind(this)} defaultChecked={dropLevel.reward} />
                                  <label htmlFor="reward" className="checkbox-toggle__label" />
                                </div>
                              </div>
                            </div>
                            <div data-drop_id={id} className="delete-btn" onClick={this.confirmDeleteDropLevel.bind(this)}>Delete Level</div>
                          </div>
                        )}
                      </div>
                    </section>
                    {/* BEING DROP LEVEL REWARD SECTION */}
                    {(dropLevel.reward) ? (
                      <section className="drop-section">
                        <label>Reward</label>
                        <div className="drop-level-details">
                          <div className="split-input">
                            <div className="split-left">
                              <div className="input-container">
                                <div><label>Title</label></div>
                                <input name="reward_title" id="drop-level-reward-title-input" placeholder={"e.g. Tote bag"} defaultValue={dropLevel.reward_title} onChange={this.prepareLevelInput.bind(this)}></input>
                              </div>
                              <div className="input-container">
                                <div><label>Description</label></div>
                                <textarea name="reward_description" id="drop-level-reward-description-input" placeholder={"e.g. Congrats! You just unlocked more swag..."} defaultValue={dropLevel.reward_description} onChange={this.prepareLevelInput.bind(this)}></textarea>
                              </div>
                            </div>
                            <div className="split-right">
                              <div className="select-container">
                                <div className="select-input-container">
                                  <div><label>Product</label></div>
                                </div>
                                <Select onChange={this.setLevelProduct.bind(this)}
                                  options={options}
                                  defaultValue={selectedLevelProductOption}
                                  menuPortalTarget={document.body}
                                  menuPosition={'fixed'}
                                />
                              </div>
                              <div className="input-container">
                                <div className="sub-section-title">
                                  <label>Image</label>
                                  <input type="file" id="reward-image" data-id={dropLevel.uuid} onChange={this.prepareLevelRewardImage} />
                                  {!levelRewardImageUrl ? (
                                    <label id="file-upload-btn" htmlFor="reward-image">Add +</label>
                                  ) : (
                                    <span></span>
                                  )}
                                </div>
                                {levelRewardImageUrl ? (
                                  <div className="achievement-image">
                                    <div className="image-container">
                                      <div style={levelRewardImageStyle} className="drop-image"></div>
                                      <div data-drop_level_id={dropLevel.uuid} className="delete-btn" onClick={this.deleteLevelRewardImage.bind(this)}>Delete</div>
                                    </div>
                                  </div>
                                ) : (
                                  <span></span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>

                    ) : (
                      <span></span>
                    )}
                    {/* END EDIT ACHIEVEMENT REWARD SECTION */}
                  </div>
                ) : (
                  <span></span>
                )}
                {/* END LEVEL SETTINGS */}
                {/* NEW FAQs */}
                {this.state.showFAQs ? (
                  <section className="drop-section">
                    <div className="faqs-header">
                      <div className="stick-left">
                        <label>FAQs</label>
                      </div>
                      {this.state.canSaveFaqs ? (
                        <div className="save-btn" id="save-faqs" onClick={this.saveFaqs.bind(this)}>
                          <div>{saveEmoji()}</div>
                        </div>
                      ) : (
                        <span></span>
                      )}
                      {this.state.canAddFaq ? (
                        <div id="add-faq-btn" onClick={this.addDropFaq.bind(this)}>Add FAQ</div>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    {faqs ? (
                      <div id="faqs-container">
                        { faqs.map((faq, i) => {
                          let position = faq.position;
                          let numberFormatted = `#${position}`;
                          let questionFieldName = `faq_${position}_question`;
                          let answerFieldName = `faq_${position}_answer`;
                          return (
                          <div className="faq-section">
                            <div className="drop-details">
                            <label className="faq-label">{numberFormatted}</label> 
                            <div id="basic-info">
                              <form>
                                {/* FAQ */}
                                <div className="qa-container">
                                  <div className="full-input">
                                    <div className="input-container">
                                      <div><label>Question</label></div>
                                      <input name={questionFieldName} id={questionFieldName} data-faq_id={faq.uuid} defaultValue={faq.question} onChange={this.prepareFaqInput.bind(this)}></input>
                                    </div>
                                    <div className="input-container">
                                      <div><label>Answer</label></div>
                                        <textarea name={answerFieldName} id={answerFieldName} data-faq_id={faq.uuid} defaultValue={faq.answer} onChange={this.prepareFaqInput.bind(this)}></textarea>
                                    </div>
                                  </div>
                                  <img alt="trash faq" data-id={faq.uuid} className="trash-faq" src="/bin.png" onClick={this.removeFaq.bind(this)} />
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        );
                        })}
                      </div>
                    ) : (
                      <span>
                      </span>
                    )}
                  </section>
                ) : (
                  <span></span>
                )}
                {/* END FAQs */}
                {/* SWEEPSTAKES */}
                {this.state.showSweepstakes ? (
                  <section className="drop-section">
                    <div className="sweepstakes-header">
                      <div className="stick-left">
                        <div>
                          <label>Sweepstakes</label>
                          { (this.state.showEntries && !this.state.showDrawWinners)  ? (
                            <div className="label-collection">
                              <label id="draw-winners-btn" onClick={this.showDrawWinners.bind(this)}>Draw Winners</label>
                              <label id="archive-entries-btn" onClick={this.archiveUserEntries.bind(this)}>Archive User Entries</label>
                            </div>
                          ) : (
                            <span></span>
                          )}
                          { this.state.showPotentialWinners ? (
                            <label id="reselect-winners-btn" onClick={this.reselectDrawWinners.bind(this)}>Reselect Winners</label>
                          ) : (
                            <span></span>
                          )}
                          { this.state.showPotentialWinners ? (
                            <label id="confirm-winners-btn" onClick={this.convertWinners.bind(this)}>Confirm Winners</label>
                          ) : (
                            <span></span>
                          )}
                        </div>
                        <div id="sweepstakes-actions">
                          { this.state.showEntries ? (
                            <div className="active-sweepstakes-switch">
                              Entries ({userEntriesCount})
                            </div>
                          ) : (
                            <div className="sweepstakes-switch" onClick={this.openSwitchItem.bind(this, "entries")}>
                              Entries ({userEntriesCount})
                            </div>
                          )}
                          <div className="switch-divider">|</div>
                          { this.state.showPotentialWinners ? (
                            <div className="active-sweepstakes-switch">
                              Potential Winners ({potentialSweepstakesWinnersCount})
                            </div>
                          ) : (
                            <div className="sweepstakes-switch" onClick={this.openSwitchItem.bind(this, "potential-winners")}>
                              Potential Winners ({potentialSweepstakesWinnersCount})
                            </div>
                          )}
                          <div className="switch-divider">|</div>
                          { this.state.showWinners ? (
                            <div className="active-sweepstakes-switch">
                              Winners ({sweepstakesWinnersCount})
                            </div>
                          ) : (
                            <div className="sweepstakes-switch" onClick={this.openSwitchItem.bind(this, "winners")}>
                              Winners ({sweepstakesWinnersCount})
                            </div>
                          )}
                        </div>
                      </div>
                      {this.state.canSaveDrawWinners ? (
                        <div className="save-btn" id="save-winners" onClick={this.saveDrawWinners.bind(this)}>
                          <div>{saveEmoji()}</div>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    {(userEntries && this.state.showEntries) ? (
                      <div id="entries">
                        {this.state.showDrawWinners ? (
                          <form id="draw-winners-form">
                            <div className="input-container">
                              <input name="number-to-draw" id="drop-number-to-draw-input" placeholder={"e.g. 100"} onChange={this.prepareDrawNumberInput.bind(this)}></input>
                            </div>
                          </form>
                        ) : (
                          <div id="entries-container">
                            { userEntries.map((entry, i) => {
                              let uuid = entry.uuid;
                              let dropUuid = entry.drop_uuid;
                              let fullName = entry.full_name;
                              let address1 = entry.address_line_1;
                              let address2 = entry.address_line_2;
                              let city = entry.city;
                              let state = entry.state;
                              let zip = entry.zip;
                              let email = entry.email;
                              let selected = entry.selected;
                              let ipAddress = entry.ip_address; 
                              return (
                              <div className="entry-container">
                                { selected ? (
                                  <img alt="selected-entry" className="selected-entry" src="/check.png" />
                                ) : (
                                  <span />
                                )}
                                <div id="entry-info">
                                  <div className="basic-info">
                                    <div className="entry-detail">
                                      {fullName}
                                    </div>
                                    <div className="entry-detail">
                                      {email}
                                    </div>
                                    <div className="entry-detail">
                                      {ipAddress}
                                    </div>
                                  </div>
                                  <div className="entry-address">
                                    <div className="entry-detail">
                                      {address1}
                                    </div>
                                    <div className="entry-detail">
                                      {address2}
                                    </div>
                                    <div className="entry-detail">
                                      <span>{city}, </span><span>{state}</span>
                                    </div>
                                    <div className="entry-detail">
                                      {zip}
                                    </div>
                                  </div>
                              </div>
                              <img alt="trash-entry" data-id={uuid} className="trash-entry" src="/bin.png" onClick={this.removeEntry.bind(this)} />
                            </div>
                          );})}
                        </div>  
                        )}
                        
                      </div>

                    ) : (
                      <div id="entries-container">
                      </div>
                    )}
                    {/* END userEntries */}
                    {(potentialSweepstakesWinners && this.state.showPotentialWinners) ? (
                      <div id="entries-container">
                        { potentialSweepstakesWinners.map((winner, i) => {
                          let entry = winner.user_entry;
                          if (!entry) {
                            return;
                          }
                          let uuid = winner.uuid;
                          let dropUuid = entry.drop_uuid;
                          let fullName = entry.full_name;
                          let address1 = entry.address_line_1;
                          let address2 = entry.address_line_2;
                          let city = entry.city;
                          let state = entry.state;
                          let zip = entry.zip;
                          let email = entry.email;
                          let selected = entry.selected;
                          let ipAddress = entry.ip_address; 
                          return (
                          <div className="entry-container">
                            { selected ? (
                              <img alt="selected-entry" className="selected-entry" src="/check.png" />
                            ) : (
                              <span />
                            )}
                            <div id="entry-info">
                              <div className="basic-info">
                                <div className="entry-detail">
                                  {fullName}
                                </div>
                                <div className="entry-detail">
                                  {email}
                                </div>
                                <div className="entry-detail">
                                  {ipAddress}
                                </div>
                              </div>
                              <div className="entry-address">
                                <div className="entry-detail">
                                  {address1}
                                </div>
                                <div className="entry-detail">
                                  {address2}
                                </div>
                                <div className="entry-detail">
                                  <span>{city}, </span><span>{state}</span>
                                </div>
                                <div className="entry-detail">
                                  {zip}
                                </div>
                              </div>
                          </div>
                          <img alt="trash-entry" data-id={uuid} className="trash-entry" src="/bin.png" onClick={this.removePotentialWinner.bind(this)} />
                          <img alt="swap-entry" data-id={uuid} className="swap-entry" src="/sync.png" onClick={this.changePotentialWinner.bind(this)} />
                        </div>
                        );})}
                      </div>
                    ) : (
                      <div id="entries-container">
                      </div>
                    )}
                    {/* END potentialSweepstakesWinners */}
                    {(sweepstakesWinners && this.state.showWinners) ? (
                      <div id="entries-container">
                        { sweepstakesWinners.map((winner, i) => {
                          let entry = winner.user_entry;
                          let uuid = winner.uuid;
                          let dropUuid = entry.drop_uuid;
                          let fullName = entry.full_name;
                          let address1 = entry.address_line_1;
                          let address2 = entry.address_line_2;
                          let city = entry.city;
                          let state = entry.state;
                          let zip = entry.zip;
                          let email = entry.email;
                          let selected = entry.selected;
                          let ipAddress = entry.ip_address; 
                          return (
                          <div className="entry-container">
                            { selected ? (
                              <img alt="selected-entry" className="selected-entry" src="/check.png" />
                            ) : (
                              <span />
                            )}
                            <div id="entry-info">
                              <div className="basic-info">
                                <div className="entry-detail">
                                  {fullName}
                                </div>
                                <div className="entry-detail">
                                  {email}
                                </div>
                                <div className="entry-detail">
                                  {ipAddress}
                                </div>
                              </div>
                              <div className="entry-address">
                                <div className="entry-detail">
                                  {address1}
                                </div>
                                <div className="entry-detail">
                                  {address2}
                                </div>
                                <div className="entry-detail">
                                  <span>{city}, </span><span>{state}</span>
                                </div>
                                <div className="entry-detail">
                                  {zip}
                                </div>
                              </div>
                          </div>
                        </div>
                        );})}
                      </div>
                    ) : (
                      <div id="entries-container">
                      </div>
                    )}
                    {/* END sweepstakesWinners */}
                  </section>
                ) : (
                  <span></span>
                )}
                {/* END SWEEPSTAKES */}
              </div>
            ) : (
              <div id="drop-missing">
                Ooops ☹️
              </div>
            )}
          </div>
        ) : (
          <div className="loader"></div>
        )}
        <div className="spacer" />
        <div className="spacer" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const products = fromJS(store.getState().getIn(['shop', 'products'])).toJS();
  const drops = fromJS(store.getState().getIn(['shop', 'drops'])).toJS();
  const dropsLoaded = fromJS(store.getState().getIn(['shop', 'dropsLoaded']));
  const productsLoaded = fromJS(store.getState().getIn(['shop', 'productsLoaded']));
  const currentDrop = fromJS(store.getState().getIn(['shop', 'currentDrop']));
  const currentAchievement = fromJS(store.getState().getIn(['shop', 'currentAchievement']));
  return { drops: drops, products: products, dropsLoaded: dropsLoaded, productsLoaded: productsLoaded, currentDrop: currentDrop, currentAchievement: currentAchievement }
}

export default connect(mapStateToProps)(Drop);
