import React, { Component } from 'react';
import {
  Route,
  Redirect
} from "react-router-dom";
import { isLoggedIn } from '../utils/Config';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      isLoggedIn() ?
        <Component {...props} />
        : <Redirect to="/login" />
    )} />
  );
};

export default PublicRoute;