import React, { Component } from 'react';
import store from '../store';
import { fromJS } from 'immutable';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Select from 'react-select';
import { sendSmsCode, verifyCode, profile } from '../actions/userActions';
import { saveEmoji } from '../utils/Config';

class NewUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      canSave: false,
      verifying: false,
      code: "",
      canResend: false
    };
  }
  componentDidUpdate() {
  }
  componentDidMount() {
  }
  prepareInput(event) {
    const { target: { value } } = event
    this.setState({ phone: value, canSave: true });
  }
  prepareCodeInput(event) {
    const { target: { value } } = event
    this.setState({ code: value, canSave: true });
  }
  sendSms() {
    let phone = this.state.phone;
    sendSmsCode(phone);
    this.setState({ verifying: true, canResend: false });
    this.timer = setInterval(() => {
      this.setState({
        canResend: true
      });
    }, 3000);
  }
  verifySmsCode() {
    let code = this.state.code;
    let phone = this.state.phone;
    verifyCode(phone, code);
  }
  onCodeKeyPress(e) {
    if (e.key === 'Enter') {
      this.verifySmsCode();
    }
  }
  onPhoneKeyPress(e) {
    if (e.key === 'Enter') {
      this.sendSms();
    }
  }
  render() {
    return (
      <div name="NewUser" id="user">
        <div id="hero-logo-container">
          <Link to="/control-tower">
            <img id="hero-logo" alt="Hero Logo" src="/h-mark.svg" />
          </Link>
        </div>
        {/* New User Section */}
        {this.state.verifying ? (
          <div id="new-user-phone-input-container">
            <div className="label-container"><label>Enter code</label></div>
            <input name="code" id="user-verify-input" placeholder={""} value={this.state.code} autoFocus onKeyPress={this.onCodeKeyPress.bind(this)} onChange={this.prepareCodeInput.bind(this)}></input>
            <div className="submit-btn" onClick={this.verifySmsCode.bind(this)}>
              Verify
            </div>
            {this.state.canResend ? (
              <div id="resend-code" onClick={this.sendSms.bind(this)}>Re-send code</div>
            ) : (
              <span></span>
            )}
          </div>
        ) : (
          <div id = "new-user-phone-input-container">
            <div className = "label-container"><label>Enter phone number</label></div >
            <input name="phone" id="user-phone-input" placeholder={""} value={this.state.phone} autoFocus onKeyPress={this.onPhoneKeyPress.bind(this)} onChange={this.prepareInput.bind(this)}></input>
            { this.state.canSave ? (
              <div className="submit-btn" onClick={this.sendSms.bind(this)}>Submit</div>
            ) : (
              <span></span>
            )}
          </div >
        )}
        <div className="spacer" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {  }
}

export default connect(mapStateToProps)(NewUser);
