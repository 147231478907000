import React, { Component } from 'react';
import '../style/App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import Landing from './Landing';
import ControlTower from './ControlTower';
import Products from './Products';
import Product from './Product';
import Drops from './Drops';
import Drop from './Drop';
import NewDrop from './NewDrop';
import User from './User';
import Users from './Users';
import NewUser from './NewUser';
import Settings from './Settings';
import Login from './Login';
import Press from './Press';
import Voting from './Voting';
import Locations from './Locations';
import RetailRequests from './RetailRequests';
import PrivateRoute from './PrivateRoute'
import { fetchProducts } from '../actions/productActions';
import { fetchDrops } from '../actions/dropActions';
import { fetchShopInfo } from '../actions/shopActions';

class App extends Component {
  componentWillMount() {
    fetchProducts();
    fetchDrops();
    fetchShopInfo();
  }
  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/" component={withRouter(Landing)}/>
            <Route path="/login" component={withRouter(Login)} />
            <PrivateRoute component={ControlTower} path="/control-tower" />
            <PrivateRoute path="/drops/:id" component={withRouter(Drop)} />
            <PrivateRoute path="/new-drop" component={withRouter(NewDrop)} />
            <PrivateRoute path="/drops" component={withRouter(Drops)} />
            <PrivateRoute path="/products/:id" component={withRouter(Product)} />
            <PrivateRoute path="/products" component={withRouter(Products)} />
            <PrivateRoute path="/users/:id" component={withRouter(User)} />
            <PrivateRoute path="/new-user" component={withRouter(NewUser)} />
            <PrivateRoute path="/users" component={withRouter(Users)} />
            <PrivateRoute path="/settings" component={withRouter(Settings)} />
            <PrivateRoute path="/press" component={withRouter(Press)} />
            <PrivateRoute path="/voting" component={withRouter(Voting)} />
            <PrivateRoute path="/locations" component={withRouter(Locations)} />
            <PrivateRoute path="/retail-requests" component={withRouter(RetailRequests)} />
          </Switch>
        </Router>
      </div>
    );
  }
}


export default App;

